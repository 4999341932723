@import (once) url('../_imports.less');

@shadow: 0.08em 0.08em 0.16em rgba(0, 0, 0, 0.5);
.component {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  /*.transform-origin(top, left);*/
  &[data-component='dropzone'] {
    z-index: 12;
  }
  &[data-drag-question-source='true'] {
    z-index: 13;
  }
  &.on-top {
    z-index: 100;
  }
  .shadow,
  &.shadow {
    box-shadow: @shadow;
  }
}

.component-shadow {
  box-shadow: @shadow;
}

.view-mode {
  .component {
    min-width: 10px;
    min-height: 10px;
  }
}

.thumbPreview {
  /* no pixels padding in thumbs */
  [data-component='text'] .text-value {
    // padding: 0;
  }
  //to save the ipad, no shadow in thumb
  .shadow,
  &.shadow {
    box-shadow: none;
  }
}
/* move the settings icon out of the way when typing text */
html.no-touch {
  .edit-mode {
    [data-component='text'].last-interaction-type-key {
      .options.grippy {
        left: -20px;
        .transition(all 0.2s);
      }
    }
    [data-component='text'].last-interaction-type-mouse {
      .options.grippy {
        left: 0px;
        .transition(all 0.2s);
      }
    }
  }
}

.thumbPreview {
  [data-component] {
    pointer-events: none;
  }
  .component {
    min-width: 0;
    min-height: 0;
  }
}

/* add presentationMode styles to components */
.presentationMode .view-mode {
  .component[data-resizable='true'][data-selectable='true'] {
    cursor: move !important;
  }
}
/*#dragQuestion.edit-mode .triggerlines {
	z-index: 1;
}*/
.triggerlines {
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  svg {
    pointer-events: none;
    position: absolute;
  }
  line {
    stroke-width: 0.8px;
    stroke-opacity: 1;
    stroke: @blue;
    stroke-dasharray: 3, 1;
  }
  line[isDragQuestionObject='true'] {
    stroke: @pink;
    stroke-dasharray: none;
    stroke-width: 0.5px;
    stroke-opacity: 0.5;
  }
}
.resizing .triggerlines,
.pre-rendered .triggerlines {
  display: none;
}
.dragQuestion-color-3 .triggerlines line[isDragQuestionObject='true'] {
  stroke: @yellow;
}
