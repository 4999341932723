@import (once) url('../../../../../../rootImports.less');

@confirmation-image-size-mobile: 145px;
@confirmation-image-size-desktop: 200px;
@confirmation-box-spacing: 20px;

.voucher-confirmation {
  display: flex;
  flex-direction: column;

  .voucher-confirmation-box {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: @confirmation-box-spacing;
    padding-top: @confirmation-image-size-mobile / 2 + @confirmation-box-spacing;
    margin-top: (@confirmation-image-size-mobile / 2) * -1;

    .confirmation-message {
      color: @cyan;
      font-weight: bold;
      font-size: 1.4rem;
      text-align: center;
    }

    .call-to-action {
      margin-top: @confirmation-box-spacing;

      &.stack > * {
        margin-right: 0.3rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .voucher-confirmation-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: @confirmation-image-size-mobile;
    height: @confirmation-image-size-mobile;
    border: 1px solid @cyan;
    margin: auto;
    position: relative;

    background-color: #fff;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    svg {
      fill: @red;
    }

    &.custom-image {
      border: none;
      border-radius: unset;
      width: 100%;
      background-size: @confirmation-image-size-mobile - @confirmation-box-spacing;
      background-position: center bottom;
    }
  }

  &:not(.small) {
    @media (min-width: @mq-sm-min) {
      flex-direction: row;
      margin-top: @confirmation-box-spacing;
      margin-right: @confirmation-image-size-desktop / 2;

      .voucher-confirmation-box {
        padding-top: @confirmation-box-spacing;
        margin-top: 0;
        max-width: @content-max-width;
        text-align: center;

        padding-left: @confirmation-image-size-desktop / 2 + @confirmation-box-spacing;
        padding-right: (@confirmation-image-size-desktop / 2) + @confirmation-box-spacing;
      }

      .voucher-confirmation-image {
        width: @confirmation-image-size-desktop;
        height: @confirmation-image-size-desktop;
        margin-right: (@confirmation-image-size-desktop / 2) * -1;
      }
    }
  }
}
