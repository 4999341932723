@import (once) url('../../../../../rootImports.less');

.ui-heading {
  font-family: @lessonup-heading;
  color: @brown-dark;
  line-height: 1.2;
  font-weight: 500;
}

.ui-heading--h0 {
  font-size: 42px;
}

.ui-heading--h1 {
  font-size: 1.5rem;
}

.ui-heading--h2 {
  font-size: 18px;
}

.ui-heading--h3 {
  font-size: 16px;
}
