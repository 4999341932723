#poll,
#openQuestion,
#question {
  .question {
    &.text-align-1 {
      text-align: center;
    }
    &.text-align-2 {
      text-align: left;
    }
    &.text-align-3 {
      text-align: right;
    }
  }
}
