@import (once) url('../../../../../rootImports.less');
@import (once) url('./LessonPlanRoot.less');

.plan-image {
  float: left;
  width: @column;
  height: @row;
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &.contain {
    background-size: contain;
  }
}
