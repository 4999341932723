@import (once) url('../../../../rootImports.less');
@channel-header-height: 300px;
@close-menu-size: 20px;

.channel-tabs-list {
  position: absolute;
  bottom: 0;
  right: 0;

  .channel-tabs {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 8px;
    max-height: ~'calc(@{channel-header-height} - @{navbar-height} - (@{close-menu-size} + 1em))';
    overflow: auto;
  }

  a {
    text-decoration: none;
  }

  .channel-tab {
    background: rgba(80, 80, 80, 0.7);
    color: #fff;
    padding: 0.5em 1em;
    cursor: pointer;
    display: none;
    transition: background-color 0.1s ease-in;

    &.active {
      background-color: #fff;
      color: @default-text;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  &.expanded {
    display: flex;

    .channel-tab {
      display: block;
    }

    .small-screen-menu-toggle {
      height: 50px;
      width: 50px;
      align-items: center;
      margin-right: 5px;
      border-radius: 100%;
      border-top-left-radius: 100%; // Necessary to override the non-expanded version's border radius.
    }
  }

  .small-screen-menu-toggle {
    background: rgba(80, 80, 80, 0.7);
    color: #fff;
    padding: 0.5em 1em;
    cursor: pointer;
    border-top-left-radius: 8px;
    display: flex;
    justify-content: flex-end;

    svg {
      height: @close-menu-size;
      width: @close-menu-size;
    }
  }

  @media (min-width: @mq-sm-min) {
    right: auto;

    .channel-tabs {
      flex-direction: row;
      margin-left: @outer-page-margin-mobile;

      .channel-tab {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        max-height: 65px;
        height: 100%;
        margin-right: 0.6em;
        display: block;
      }
    }
  }

  @media (min-width: @mq-md-min) {
    margin-left: @outer-page-margin-desktop;
  }
}
