.aspect-ratio-box {
  position: relative;
  width: 100%;
}

.aspect-ratio-box-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
