.register-login-modal {
  padding-top: 0 !important;
  color: auto;
}

.register-or-login-title {
  height: 64px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
