@import (once) url('../_imports.less');

@16-9-aspect-ratio-height: 9/16;
@calculated-height: @size-thumbnail * @16-9-aspect-ratio-height;

.thumbnail-wrapper {
  position: relative;
}

.explorer-thumbnail {
  padding: @cell-spacer !important;
  width: @size-thumbnail + 2 * @cell-spacer;
  min-height: @size-thumbnail + 2 * @cell-spacer;
  justify-content: center;
  position: relative;
  &__thumbnail {
    object-fit: cover;
    object-position: top;
    width: @size-thumbnail - @cell-spacer;
    min-height: @calculated-height;
    vertical-align: top;
    svg {
      vertical-align: top;
    }
    &--content-thumbnail {
      height: @calculated-height;
      object-fit: cover;
      object-position: 0 0.25rem;
    }
  }
  &--over {
    background-color: @black-trans;
  }
}
