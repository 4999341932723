@import (once) url('../_imports.less');
.component-video {
  // show a little area so we can finf the component if it has no video
  .edit-mode & {
    background-color: rgba(0, 0, 0, 0.15);
  }
  font-size: 40%;

  .playerWrapper {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #000;
    iframe,
    video {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: relative;
    }

    .icon {
      width: 100%;
      height: 1.5em;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }
}

// youtube video aspect ratio can be modified. Thumb should reflect this
.videoType-youtube {
  .playerWrapper {
    background-size: contain;
  }
}

// vimeo player maintains aspect ratio. Thumb should reflect this
.videoType-vimeo {
  .playerWrapper {
    background-size: contain;
  }
}

// settings dialog
.video-settings {
  position: relative;
  min-height: 120px;
  .video-input {
    width: 450px;
    position: relative;

    .icon {
      position: absolute;
      bottom: 0;
      right: -28px;
      width: 25px;
      height: 25px;
      background-position: center;
    }
  }
  .video-thumb {
    position: relative;
    height: 90px;
    width: 160px;
    margin-right: 10px;
    margin-bottom: 14px;
    float: left;
    .video-duration {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: black;
      color: white;
      text-transform: uppercase;
      padding: 5px 10px;
    }
    .thumb-image,
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
    }
    .thumb-image {
      background-size: cover;
    }
    .icon {
      background-size: 1%;
    }
  }
}
