@import (once) url('../../../../../../rootImports.less');

@padding-top: 80px;

.pin-search-lessonpage {
  display: flex;
  justify-content: space-around;
  padding: @padding-top 0 0 0;
  overflow-y: hidden;
  height: calc(100vh - @padding-top);

  margin-left: 1.6em;
  margin-right: 1.6em;

  .pin-selection-large {
    margin-top: 1em;
  }

  .lesson-title {
    font-size: 28px;
    color: @blue;
    text-decoration: none;
    margin: 2px;
  }
  .lesson-preview {
    width: 100%;
    max-width: 1000px;
    justify-content: space-between;
  }

  .lesson-details {
    max-width: 70%;

    .lesson-page__back {
      margin-top: 0;
      margin-bottom: 0;
      cursor: pointer;
      color: @blue;
    }
  }
}

.pin-search-preview-container {
  width: 600px;
}
