@import (once) url('_imports.less');
// Pretty much a component that'll work whereever instead of just the component area

.miniaudioplayer {
  position: relative;
  max-height: 100%;
  height: 100%;
  width: 25%;

  border-radius: 100%;

  .border {
    position: absolute;
    width: 95%;
    height: 95%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3000px;
    background: #000;
  }

  .core {
    position: absolute;
    width: 75%;
    height: 75%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3000px;
    background: #fff;
    background-color: #ca4cfd;
  }

  &.answer-audio-align-right {
    right: 0;
  }

  &.answer-audio-align-left {
    left: 0;
  }

  // .answer-audio-border {
  // 	position: absolute;
  // 	width: 95%;
  // 	height: 95%;
  // 	left: 50%;
  // 	top: 50%;
  // 	transform: translate(-50%, -50%);
  // 	border-radius: 100%;
  // 	background: #000;
  // }

  .answer-audio-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.answer-audio-paused {
    .icon-playing-state.icon-dark {
      background-image: url('../img/icons/black/play.png');
    }
  }

  &.answer-audio-playing {
    .icon-playing-state.icon-dark {
      background-image: url('@{assetsUrl}/img/icons/black/pause.png');
    }
  }

  .icon-playing-state.icon-dark {
    background-image: url('@{assetsUrl}/img/icons/black/speaker.png');
  }

  .icon-playing-state {
    background-image: url('@{assetsUrl}/img/icons/white/speaker.png');
  }

  &.answer-audio-paused {
    .icon-playing-state {
      background-image: url('../img/icons/white/play.png');
    }
  }

  &.answer-audio-playing {
    .icon-playing-state {
      background-image: url('@{assetsUrl}/img/icons/white/pause.png');
    }
  }
}
