.appearance (@val) {
  -webkit-appearance: @val;
  -moz-appearance: @val;
  -ms-appearance: @val;
  appearance: @val;
}
.text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: @string;
}
.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow: @string;
  box-shadow: @string;
}
.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
}
.inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}
.box-sizing (@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing: @type;
  box-sizing: @type;
}
.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  -webkit-border-top-right-radius: @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius: @bottomleft;
  -webkit-border-top-left-radius: @topleft;

  -moz-border-radius-topright: @topright;
  -moz-border-radius-bottomright: @bottomright;
  -moz-border-radius-bottomleft: @bottomleft;
  -moz-border-radius-topleft: @topleft;

  border-top-right-radius: @topright;
  border-bottom-right-radius: @bottomright;
  border-bottom-left-radius: @bottomleft;
  border-top-left-radius: @topleft;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.opacity (@opacity: 0.5) {
  -webkit-opacity: @opacity;
  -moz-opacity: @opacity;
  opacity: @opacity;
  @opperc: @opacity * 100;
  -ms-filter: ~'progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})';
  filter: ~'alpha(opacity=@{opperc})';
}

.animation (@string) {
  -webkit-animation: @string;
  -moz-animation: @string;
  -ms-animation: @string;
  animation: @string;
}

.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}
.transform(@string) {
  -webkit-transform: @string;
  -moz-transform: @string;
  -ms-transform: @string;
  -o-transform: @string;
  transform: @string;
}
.scale (@factor) {
  -webkit-transform: scale(@factor);
  -moz-transform: scale(@factor);
  -ms-transform: scale(@factor);
  -o-transform: scale(@factor);
  transform: scale(@factor);
}
.scaleY (@factor) {
  -webkit-transform: scaleY(@factor);
  -moz-transform: scaleY(@factor);
  -ms-transform: scaleY(@factor);
  -o-transform: scaleY(@factor);
  transform: scaleY(@factor);
}
.rotate (@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
  transform: rotate(@deg);
}
.skew (@deg, @deg2) {
  -webkit-transform: skew(@deg, @deg2);
  -moz-transform: skew(@deg, @deg2);
  -ms-transform: skew(@deg, @deg2);
  -o-transform: skew(@deg, @deg2);
  transform: skew(@deg, @deg2);
}
.translate (@x, @y:0) {
  -webkit-transform: translate(@x, @y);
  -moz-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y);
  -o-transform: translate(@x, @y);
  transform: translate(@x, @y);
}
.translatex (@x) {
  -webkit-transform: translateX(@x);
  -moz-transform: translateX(@x);
  -ms-transform: translateX(@x);
  -o-transform: translateX(@x);
}
.translatey (@y) {
  -webkit-transform: translateY(@y);
  -moz-transform: translateY(@y);
  -ms-transform: translateY(@y);
  -o-transform: translateY(@y);
  transform: translateY(@y);
}
.translate3d (@x, @y: 0, @z: 0) {
  -webkit-transform: translate3d(@x, @y, @z);
  -moz-transform: translate3d(@x, @y, @z);
  -ms-transform: translate3d(@x, @y, @z);
  -o-transform: translate3d(@x, @y, @z);
  transform: translate3d(@x, @y, @z);
}
.perspective (@value: 1000) {
  -webkit-perspective: @value;
  -moz-perspective: @value;
  -ms-perspective: @value;
  perspective: @value;
}
.transform-origin (@x:center, @y:center) {
  -webkit-transform-origin: @x @y;
  -moz-transform-origin: @x @y;
  -ms-transform-origin: @x @y;
  -o-transform-origin: @x @y;
  transform-origin: @x @y;
}
.user-select (@argument: none) {
  -webkit-user-select: @argument;
  -moz-user-select: @argument;
  -ms-user-select: @argument;
  user-select: @argument;
}
.user-drag (@argument: none) {
  -webkit-user-drag: @argument;
  -moz-user-drag: @argument;
  -ms-user-drag: @argument;
  user-drag: @argument;
}
.aspect-ratio(@width, @height) {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: (@height / @width) * 100%;
  }
  > [data-aspect-ratio='inner'] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.vertical-align( @align: middle ) {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: @align;
  }
  & > * {
    vertical-align: @align;
  }
}
.columns ( @columns: 1 ) {
  -webkit-columns: @columns;
  -moz-columns: @columns;
  -ms-columns: @columns;
  columns: @columns;
  // column-gap: 2em;
}
.responsive-font-size(@width, @screenwidth) {
  @pinRatio: 10/16 * 9;
  @scale: @width / @screenwidth;
  @size: @pinRatio * @scale;
  font-size: unit(@size, vw);
}

.consecutive-child-animations(@iterations: 5, @delay: 0.3) {
  .span-loop (@i) when (@i > 1) {
    &:nth-child(@{i}) {
      animation-delay: (@delay * (@i - 1));
    }
    .span-loop(@i - 1);
  }

  .span-loop(@iterations);
}

.line-clamp(@max-height, @max-lines) {
  // ie11 fallback for line-clamp, does not include ellipsis
  max-height: @max-height * @max-lines;
  overflow: hidden;

  @supports (-webkit-line-clamp: @max-lines) {
    display: -webkit-box;
    -webkit-line-clamp: @max-lines;
    -webkit-box-orient: vertical;
    height: auto;
  }
}
