@import (once) url('../_imports.less');

/* some colors and layouts may overlap with slide, but some don't - so don't merge! */

.openQuestion-color-1 {
  .content-background {
    background-color: @blue-dark;
  }
  color: #fff;
}
.openQuestion-color-2 {
  .content-background {
    background-color: @yellow;
  }
  color: #000;
}
.openQuestion-color-3 {
  .content-background {
    background-color: @pink;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.openQuestion-color-4 {
  .content-background {
    background-color: @cyan;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.openQuestion-color-5 {
  .content-background {
    background-color: #000;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.openQuestion-color-6 {
  .content-background {
    background-color: @blue;
  }
  color: darken(@blue, 45);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.openQuestion-color-7 {
  .content-background {
    background-color: @orange;
  }
  color: darken(@orange, 40);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.openQuestion-color-8 {
  .content-background {
    background-color: @correct-light;
  }
  color: darken(@correct, 25);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.openQuestion-color-9 {
  .content-background {
    background-color: #fff;
  }
  color: #111;
  &.edit-mode .text {
    border-color: rgba(0, 0, 0, 0.5);
  }
  textarea.student-open-question {
    border: solid @blue 2px !important;
  }
}

#openQuestion {
  &.openQuestion-layout-1 {
    .question,
    #title {
      top: 3%;
      left: 5%;
      right: 5%;
      bottom: 70%;
      width: 90%;
    }
    .image {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
    }
  }

  &.openQuestion-layout-2 {
    .question,
    #title {
      left: 0;
      top: 3%;
      right: 50%;
      bottom: 70%;
      width: 50%;
    }
    .image {
      left: 50%;
      top: 0;
      right: 0;
      bottom: 70%;
      background-size: contain;
    }
  }

  &.openQuestion-layout-3 {
    .question,
    #title {
      left: 50%;
      top: 3%;
      right: 0;
      bottom: 70%;
      width: 50%;
    }
    .image {
      left: 0%;
      top: 0;
      right: 50%;
      bottom: 70%;
      background-size: contain;
    }
  }

  &.openQuestion-layout-4 {
    .question {
      display: none;
    }
    .image {
      left: 0;
      top: 0;
      right: 0;
      bottom: 70%;
      background-size: contain;
    }
  }

  &.openQuestion-layout-5 {
    .question {
      display: none;
    }
    .image {
      display: none;
    }
  }
}

.presentationMode #openQuestion.openQuestion-layout-1 .image {
  /* fixed underneath scrolling answers */
  position: fixed;
}
