@import (once) url('../../../../../rootImports.less');

.card-content-for-organization {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  &__card-content {
    max-height: 100%;
    margin-right: @spacer * 8;
  }

  &__card-title {
    font-size: 0.75rem;
  }

  &__card-button {
    margin-left: auto;
  }
}
