@import (once) url('../../../../../../../rootImports.less');

.school-select-option {
  &__option {
    display: flex;
    flex-direction: column;
  }

  &__title {
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__address {
    color: @black;
    opacity: 0.3;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0;
  }
}
