.view-components {
  div.component {
    z-index: auto;

    &.interactive {
      cursor: pointer;
    }

    .timer-border,
    .timer-center {
      z-index: inherit;
    }
  }
}
