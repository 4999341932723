@import (once) url('../../../../rootImports.less');

.error-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .error-header {
    width: 100%;
    height: 200px;
    background-color: @blue;
    text-align: center;
    margin: 0 0 20px;
    font-family: @lessonup-heading, sans-serif;
    display: flex;
    // justify-content: center;
    align-items: center;
    background-size: cover;

    .glass-pane {
      position: absolute;
      width: 100%;
      height: 200px;
      background: linear-gradient(to bottom, @glasspane, transparent);
      z-index: 1;
    }

    .error-header-logo {
      color: @white;
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
    }

    .channel-icon {
      width: 100px;
      margin-right: 1em;
      margin-left: 50px;
      z-index: 2;
    }

    .channel-banner {
      z-index: 2;
    }

    .channel-banner-title {
      text-align: start;
      font-family: @lessonup-heading;
      font-size: 1.6em;
      margin-bottom: -20px;
      color: @white;
    }

    .channel-banner-subtitle {
      font-family: @lessonup-title;
      font-size: 16px;
      font-weight: 300;
      text-align: left;
      color: @white;
      line-height: 1.4;
    }
  }
  .error-page-content {
    flex-grow: 0;
    padding: 1.2em;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 40px;

    .error-title {
      font-family: @lessonup-heading, sans-serif;
      font-weight: bold;
      margin-bottom: 18px;
    }

    .error-description {
      white-space: break-spaces;
      font-size: 18px;
      margin-top: 18px;
      margin-bottom: 0px;
    }
    svg {
      width: auto;
      max-width: 100vw;
    }
    .error-actions {
      flex-direction: row;
      margin-top: 18px;

      .error-link {
        text-decoration: none;
        color: @white;
        .error-page-login {
          display: inline-block;
          margin-left: 30px;
          margin-top: 10px;
          color: @grey-light2;
          text-decoration: underline;
          font-weight: 700;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .error-resolution-suggestions {
      padding-left: 20px;
      margin-top: 0px;
      margin-bottom: 18px;
      li {
        padding-top: 10px;
        padding-left: 10px;
        font-size: 18px;
      }
    }
  }

  @media (min-width: 778px) {
    .error-header {
      .channel-banner-title {
        font-size: 2.2em;
      }
    }
  }
}
