@import (once) url('../../../../rootImports.less');

@media screen and (min-width: @mq-sm-min) {
  .login-modal {
    overflow: visible;

    .modal__content {
      padding-left: 0;
      padding-right: 0;
    }

    .login__page {
      &.hide {
        display: none;
      }
    }

    .login__page-banner {
      display: flex;
      flex-flow: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }

    .login__page-options {
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
    }

    .login__page-option {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin: 1em 0;
      align-items: center;
      flex-grow: 1;
      text-align: center;

      h3 {
        margin: 0;
        margin-bottom: 0.8em;
      }

      &.login__page-option-fixed-width {
        width: ~'calc((100% - 2em) / 2)';
        justify-content: space-around;
      }

      &.option--button-margins {
        .action-button {
          margin-top: 1em;
        }
      }

      .login__page-grow {
        flex-grow: 1;
      }
    }

    .login__page-option--center {
      justify-content: space-around;
      align-items: center;
    }

    .login__option-description {
      flex-grow: 1;
    }

    .login__page-divider {
      height: 250px;
      align-self: center;
    }

    .image-tile-wrapper {
      height: 100%;

      .image-tile {
        height: inherit;
      }

      .tile-svg {
        height: 50%;
        bottom: 0;
      }
    }
  }
}

@media screen and (max-width: @mq-xs-max) {
  .modal__content {
    padding: 0;
  }

  .login-modal {
    height: 100vh;
    width: 100vw;
    max-width: auto;

    .login__content {
      display: flex;
      flex-direction: column;
    }

    .login__page {
      &.hide {
        display: none;
      }

      height: 100%;
    }

    .login__page-banner {
      display: flex;
      flex-flow: wrap;
      flex-direction: row;
      width: 90%;
    }

    .login__page-banner--col1 {
      display: flex;
      flex-basis: 30%;
    }

    .login__page-banner--col2 {
      display: flex;
      flex-basis: 60%;
    }

    .login__page-logo {
      margin: auto;
    }

    .login__page-options {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-start;
    }

    .login__page-option {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      h3 {
        margin: 0 0 1em;
      }
    }
  }
}

.login-modal {
  .back-link {
    font-size: 0.9em;
    display: block;
    text-align: center;
    margin: 1em;
  }

  .password-forgotten-option {
    @media (min-width: @mq-sm-min) {
      width: 45%;
      justify-content: space-between;
    }

    .password-forgotten-title {
      margin-bottom: 0;
    }
  }

  .password-forgotten-form {
    width: 100%;
  }

  @media (min-width: @mq-sm-min) {
    .login-select-application {
      margin-left: 0;
      justify-content: center;

      .image-tile-wrapper {
        margin-left: 0;
        margin-right: 0;
        padding: 0;

        .image-tile {
          margin-top: 0;
          margin-bottom: 0;

          &.teacher-tile {
            .tile-svg {
              left: -10%;
            }
          }

          &.student-tile {
            .tile-svg {
              right: -10%;
            }
          }
        }
      }
    }
  }
}

.login__page-divider {
  width: 100%;
  background-color: @outline;
  height: 3px;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: @mq-sm-min) {
    width: 3px;

    height: 100%;
    margin: 12px 0;
  }
}
