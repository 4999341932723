@import (once) url('../../../../../rootImports.less');

@form-width-small: 280px;
@form-width: 500px;
@default-button-margin: 1em;

.default-form {
  margin-top: 10px;

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='search'],
  input[type='date'] {
    display: block;
    background: #fff;
    height: 40px;
    font-family: @lessonup-body-new;
    font-size: 16px;
    line-height: 1.3em;
    padding: 0.8em;
    margin: 5px auto;
    border-radius: @input-border-radius;
    border: solid @silver 0.05rem;
    outline: 0;

    &.input--error {
      border: 1px solid @red;
    }

    &::placeholder {
      color: @grey-light2;
      font-size: 0.9em;
    }

    &.form-input-medium {
      height: 2.4em;
    }
    &.form-input-small {
      height: 2em;
    }
  }
  select {
    display: block;
    width: @form-width;
    margin: 0 10px 10px 0;
  }
  textarea {
    display: block;
    border: solid @blue 2px;
    padding: 20px;
    background: #fff;
    font-family: @lessonup-body-new;
    margin: 0 10px 10px 0;
    resize: none;
  }
  input[type='submit'],
  input[type='button'] {
    display: inline-block;
    background-color: @pink;
    padding: 10px;
    line-height: 10px;
    .font-montserrat;
    font-size: 15px;
    text-decoration: none;
    color: white;
  }

  .action-button {
    display: block;
    margin: 30px auto auto auto;
  }

  label {
    display: inline-block;
    margin: 1.4em 4px 0 0;
  }

  .error-list {
    color: @red;
  }
  .error {
    color: @red;
    font-size: 80%;
    margin: 10px;
  }

  .center-error {
    text-align: center;
  }
}

.default-form--flex {
  flex: 2;
  height: 100%;
  display: flex;
  flex-direction: column;

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'] {
    margin: 2px 0;
  }
}

@media (max-width: 700px) {
  .default-form {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='number'],
    select,
    textarea,
    .invalid {
      max-width: @form-width-small;
    }
  }
}
.default-form.default-form--full {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  select,
  textarea,
  .invalid {
    width: 100%;
  }
}

.placeholder(@color) {
  &::placeholder {
    color: @color;
  }

  &::-moz-placeholder {
    color: @color;
  }
}
