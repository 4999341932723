@import (once) url('../../../../../rootImports.less');

.request-result-for-organization {
  display: flex;
  align-items: center;

  svg {
    margin-right: @spacer * 2;
  }

  &--error {
    color: @red-new;
  }

  &--success {
    color: @green-new;
  }
}
