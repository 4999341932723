@import (once) url('../../../../../rootImports.less');

.taglist {
  &.taglist--decorative-dot {
    .taglist__list-element {
      position: relative;
      display: inline-block;
    }

    .taglist__list-element:after {
      content: '\00B7';
      position: absolute;
      right: -0.46em;
    }

    .taglist__list-element:last-of-type:after {
      content: '';
    }
  }

  .tag-element__tooltip {
    opacity: 0;
    display: none;
  }

  .taglist__list-element:hover {
    .tag-element__tooltip {
      opacity: 1;
      display: unset;
    }
  }
}

.taglist--clickable .taglist__list-element {
  cursor: pointer;
}
