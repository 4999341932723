@import (once) url('_imports.less');

button.content-item-button {
  border: 1px solid transparent;
  margin: 0;
  padding: 0;
  padding-right: 8px;
  width: auto;
  overflow: visible;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:active {
    border-top: solid darken(@pink, 40) 1px;
    border-bottom: 1px solid transparent;
  }
}

.minibutton {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: auto 75% !important;
  background-position: center center;
}
.text-button {
  display: inline-block;
  color: @default-text;
  text-decoration: underline;
  height: 30px;
  line-height: 31px;
  font-size: 12px;
  font-family: @lessonup-body;
  text-transform: none;
  cursor: pointer;
  &.icon {
    background-repeat: no-repeat;
    background-position: 7px center;
    background-size: 13px auto;
    padding-left: 32px;
  }
  &.icon-only {
    padding-left: 30px;
    vertical-align: bottom;
    height: 32px;
  }
  &.text-button-2-lines {
    height: 50px;
    line-height: 25px;
    background-position: 7px 6px;
  }
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  .explain {
    background-color: #33c0f6;
    color: white;
    border-radius: 30px;
    display: inline-block;
    height: 20px;
    width: 20px;
    line-height: 20px;
    cursor: help;
    position: relative;
    &:before {
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
    &:after {
      content: '?';
      position: absolute;
      right: 7.5px;
    }
  }
}

#playercontent-inner {
  .text-button {
    background-position: 0.5em center;
    font-size: 94%;
    line-height: 2em;
    height: 2em;
    border-radius: 0.2em;
    &.icon {
      padding-left: 2em;
      background-size: 1em auto;
    }
  }
}
.content-item-button,
#common-popup-box .content-item-button {
  display: inline-block;
  background-color: @pink;
  color: #fff;
  text-decoration: none;
  font-family: @lessonup-body;
  text-transform: none;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 5px;
  padding: 0 10px;

  border-bottom: solid darken(@pink, 40) 2px;
  text-align: left;
  font-style: normal;
  vertical-align: top;
  cursor: pointer;

  input[type='file'],
  input[type='file']::-webkit-file-upload-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    /*background: blue;*/
  }
  &.use-em {
    font-size: inherit;
    line-height: 2em;
    height: 2em;
    border-radius: 0.2em;
    padding: 0 0.8em;
  }

  &.bg-pink-lite {
    background-color: lighten(@pink, 8);
  }
  &.bg-white,
  &.bg-white2 {
    border-bottom: solid #333 2px;
  }
  &.bg-black {
    border-bottom: solid #000 2px;
  }
  &.bg-yellow {
    border-bottom: solid darken(@yellow, 40) 2px;
  }
  &.bg-red {
    border-bottom: solid darken(@red, 40) 2px;
  }
  &.bg-blue {
    border-bottom: solid darken(@blue, 40) 2px;
  }
  &.bg-blue-dark {
    border-bottom: solid darken(@blue-dark, 20) 2px;
  }

  &.fg-black {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  &.no-text.icon {
    padding-left: 20px;
  }

  &.light-border {
    border: 1px solid #b9b9b9;
    border-bottom: solid darken(@pink, 40) 2px;
  }

  &.padded {
    padding: 18px;
    line-height: 4px;
  }

  &.icon {
    background-repeat: no-repeat;
    background-position: 7px center;
    background-size: 15px auto;
    padding-left: 32px;
    position: relative;
    &.icon-only {
      padding-left: 0;
      width: 32px;
      background-position: center center;
      background-size: auto 20px;
    }
  }

  &.icon.event-loading {
    background-image: none;
  }

  &.waiting,
  &.event-loading {
    background-repeat: no-repeat;
    padding-left: 32px;
    position: relative;
    opacity: 0.6;
    pointer-events: none;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      width: 25px;
      content: '';
      background-image: url('/img/rocket3.png') !important;
      background-repeat: no-repeat;
      background-position: 7px center;
      background-size: 15px auto;
      .animation (fly-small infinite 2s ease-in-out);
    }
    &.fg-black:after,
    &.icon-black {
      background-image: url('/img/rocket2.png') !important;
    }
  }

  &.icon-after {
    position: relative;
    padding-right: 32px;
    &:after {
      position: absolute;
      right: 7px;
      top: 5px;
      width: 20px;
      height: 20px;
      content: '';
      background-size: 15px auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &.icon.icon-border {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 26px;
      height: 28px;
      border-right: solid #fff 1px;
      top: 0px;
      opacity: 0.4;
    }
  }

  &.small-wtf-button {
    position: relative;
    margin-top: -2px;
    padding-left: 20px;
    background-color: transparent;
    border: none;
    height: 15px;
    background-size: auto 80%;
  }
  &.disabled {
    cursor: default;
    opacity: 0.3;
    pointer-events: none;
  }
  &.big {
    height: 40px;
    line-height: 40px;
    background-position: 10px 50%;
    background-size: auto 50%;
    padding: 0 14px 0 40px;
  }
  &.icon-small {
    background-size: 11px auto;
    padding-left: 24px;
  }
  &.float {
    float: left;
    display: block;
    margin: 0 10px 0 0;
  }
  &.float-right {
    float: right;
    display: block;
  }

  &.rounded {
    border-radius: 20px;
    background-position: 11px center;
    padding-left: 32px;
    padding-right: 15px;
  }

  &.label-optional {
    /* for smaller screens don't show the text labels */
    @media (max-width: 1024px) {
      padding-left: 20px;
      .button-label {
        display: none;
      }
    }
  }
  &.has-secondary {
    border-radius: 5px 3px 3px 5px;
    margin-right: 0 !important;
  }

  &.no-overflow {
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
  &.secondary {
    position: relative;
    border-radius: 3px 5px 5px 3px;
    margin-left: -2px;
    /*&:before {
			content: ''; display: block; position: absolute;
			top: 1px; bottom: 1px; left: 0; width: 1px;
			background: rgba(255,255,255,.5);
		}*/
    .sub-buttons {
      .inner {
        padding: 10px;
      }
      .option {
        border-radius: 0px;
        border-bottom: solid rgba(255, 255, 255, 0.2) 1px;
        margin-bottom: 2px;
        .right {
          color: rgba(255, 255, 255, 0.6);
          margin-left: 10px;
          font-size: 12px;
        }
      }
      &.pink {
        .option {
          &:hover {
            background: #fff;
            color: @default-text;
            .right {
              color: @default-text;
            }
          }
        }
      }
    }
  }
}
