@import (once) url('../../../../../rootImports.less');

@button-border-radius: 5px;
@button-box-shadow-height: 3px;
@button-border-width: 2px;
@default-button-padding: 0.7em;

a.action-button {
  text-decoration: none;
}

.action-button:not(:only-of-type) {
  margin-right: 0.3em;
}

.action-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding-left: @default-button-padding;
  padding-right: @default-button-padding;
  border-radius: @button-border-radius;
  border: 2px solid @brown-dark;
  box-shadow: 0 @button-box-shadow-height 0 0 @brown-dark;
  font-weight: 500;
  font-family: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  box-sizing: border-box;
  vertical-align: top;
  position: relative;
  fill: currentColor;
  max-width: 100%;
  margin-bottom: @button-box-shadow-height;
  .ellipsis;

  img {
    max-width: 1em;
  }
  &.tipped {
    overflow: visible;
  }
  .tooltip {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out 0.3s;
  }
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
      transition: all 0.3s ease-out 0.3s;
    }
  }

  .icon {
    display: inline-block;
    max-width: 10%;
    height: 3em;
    max-height: 1em;
    max-width: 1em;
    vertical-align: middle;
    margin-right: 0.5em;
  }

  &:focus {
    outline: none;
  }

  &:active {
    // TODO Better pressed state
    transform: translateY(2px);
    box-shadow: none;
  }

  &.icon {
    background-repeat: no-repeat;
    background-position: 7px center;
    background-size: 15px auto;
    padding-left: 32px;

    &.icon-only {
      padding-left: 0;
      width: 32px;
      background-position: center center;
      background-size: auto 20px;
    }
  }

  &:disabled:not(.disable-disabled-styles) {
    pointer-events: none;
    opacity: 0.4;
  }
  &.has-secondary {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0.05em;
    &.version-modern {
      margin-right: 0.08em;
    }
  }
  &.is-secondary {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.has-text-right {
    justify-content: space-between;
  }
}

.action-button--small {
  height: 2rem;
  padding: 0 @default-button-padding;
}

a.action-button--small {
  line-height: 1.9;
  align-items: center;
}

.action-button--medium {
  height: 2.5rem;
}

.action-button--large {
  height: 3rem;
}

.action-button--block {
  display: block;
  width: 100%;
}

.action-button-primary {
  background-color: @red;
  color: @white;
}

.action-button-secondary {
  background-color: @yellow;
  color: @brown-dark;
}

.action-button-tertiary {
  background-color: @cyan;
  color: @white;
}

.action-button-quaternary {
  background-color: @blue;
  color: @white;
}

.action-button-light {
  background-color: @white;
  color: @brown-dark;
}

.action-button-grey {
  background-color: @white-2;
  color: @brown-dark;
}

.action-button-green {
  background-color: @green;
  color: @white;
}

.action-button-orange {
  background-color: @orange;
  color: @white;
}

.action-button-purple {
  background-color: @purple;
  color: @white;
}

.action-button-blue {
  color: @white;
  background-color: @blue;
}

.action-button-transparent {
  background-color: transparent;
  color: @brown-dark;
}

.version-modern {
  box-shadow: none;
  border: hidden;
  border-radius: 5px;
  margin-bottom: 0;
  transition: all 200ms;

  &:hover {
    box-shadow: 0 2px 4px 0 @silver;
  }

  &:active {
    transform: none;
  }
}
