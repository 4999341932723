@import (once) url('../_imports.less');

/* some colors and layouts may overlap with slide, but some don't - so don't merge! */

.dragQuestion-color-1 {
  background-color: @blue-dark;
  color: #fff;
}
.dragQuestion-color-2 {
  background-color: @yellow;
  color: #000;
}
.dragQuestion-color-3 {
  background-color: @pink;
  color: #fff;
}
.dragQuestion-color-4 {
  background-color: @cyan;
  color: #fff;
}
.dragQuestion-color-5 {
  background-color: #000;
  color: #fff;
}
.dragQuestion-color-6 {
  background-color: @blue;
  color: darken(@blue, 45);
}
.dragQuestion-color-7 {
  background-color: @orange;
  color: darken(@orange, 40);
}
.dragQuestion-color-8 {
  background-color: @correct-light;
  color: darken(@correct, 25);
}
.dragQuestion-color-9 {
  background-color: #fff;
  color: #111;
}
:not(.show-preview-true).state-game,
.show-results-true {
  .dragQuestion-color-9 {
    background-color: @blue-dark;
    color: #fff;
  }
}

#dragQuestion {
  &.dragQuestion-layout-1 {
    .image {
    }
  }
  &.dragQuestion-layout-2 {
    .image {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
    }
  }
}

// .presentationMode #dragQuestion.dragQuestion-layout-2 .image {
// 	/* fixed underneath scrolling answers */
// 	position: fixed;
// }
