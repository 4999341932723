@import (once) url('../_imports.less');

.component-timer {
  @fill: green; //backup fill, should never show
  @nofill: #f1f1f1;
  .timer-border {
    position: absolute;
    background-color: @nofill;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 0 0.4em 0.05em rgba(0, 0, 0, 0.5);
  }
  .mask {
    position: absolute;
    border: red;
    opacity: 1;
    background-color: @fill;
    .transition (transform 0.14s linear);
    &.maskmain {
      transform-origin: right center;
      top: -5%;
      left: -5%;
      right: 50%;
      height: 110%;
    }
    &.mask1,
    &.mask2,
    &.mask3,
    &.mask4 {
      height: 56%;
    }
    &.mask1,
    &.mask2 {
      right: -5%;
      left: 50%;
    }
    &.mask1 {
      top: -5%;
    }
    &.mask2 {
      bottom: -5%;
    }
    &.mask3,
    &.mask4 {
      background-color: @nofill;
      left: -5%;
      //on 50% sometimes mainmask shows a little
      right: 49.5%;
    }
    &.mask3 {
      bottom: -5%;
    }
    &.mask4 {
      top: -5%;
    }
  }
  .timer-center {
    background-color: white;
    opacity: 1;
    color: @default-text;
    position: absolute;
    z-index: 1;
    border-radius: 100%;
    margin: 12%;
    width: 76%;
    height: 76%;
    .type {
      font-size: 40%;
      top: 10%;
      left: 0;
      right: 0;
      text-align: center;
      position: absolute;
    }
    .time {
      position: absolute;
      cursor: text;
      .font-courier;
      top: 40%;
      left: 0;
      right: 0;
      text-align: center;
      .small {
        font-size: 40%;
      }
      &.edit {
        top: 23%;
      }
    }
    .new-time {
      padding: 1%;
      font-size: 60%;
      position: absolute;
      top: 47%;
      width: 60%;
      height: 1.4em;
      left: 10%;
      text-align: right;
      min-width: 0;
      border-radius: 0;
      &.placeholder {
        opacity: 0.5;
      }
    }
    .time-confirm {
      position: absolute;
      top: 47%;
      right: 8%;
      font-size: 41%;
      height: 2em;
      line-height: 2em;
      width: 3em;
      padding: 0;
      text-align: center;
    }
  }
  .timer-button {
    cursor: pointer;
    font-size: 34%;
    position: absolute;
    bottom: 20%;
    left: 17%;
    color: white;
    padding: 3px 7px 0 7px;
    .font-odin;
    text-transform: uppercase;
    @percentRound: 18%;
    background-color: @blue-dark;
    border-top-left-radius: @percentRound 50%;
    border-top-right-radius: @percentRound 50%;
    border-bottom-left-radius: @percentRound 50%;
    border-bottom-right-radius: @percentRound 50%;
    width: 30%;
    line-height: 1.7em;
    text-align: center;
    &.button-right {
      left: 53%;
    }
  }
}

#player[data-shared-screen] .component-timer {
  display: none;
}
