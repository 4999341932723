@import (once) url('../../../../../../rootImports.less');

.lesson-preview.v2 {
  .lesson-preview__pin-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  // override the lesson-overview styles
  .lesson-preview__player {
    @media (min-width: @mq-sm-min) {
      font-size: inherit;
    }

    @media (min-width: @mq-md-min) {
      font-size: inherit;
    }

    @media (min-width: @mq-lg-min) {
      font-size: inherit;
    }
  }
}
