@import (once) url('../../../../../rootImports.less');

.checkbox-group {
  .checkbox.checkbox--checked {
    .checkmark {
      position: absolute;
      background-image: url('/search/public/img/icons/white/checkmark.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 75% auto;
      height: 100%;
      width: 100%;
      background-color: @cyan;
      border-radius: 3px;
    }
  }

  &.checkbox-accented-border {
    .checkbox {
      background-color: #fff;
      box-shadow: 0 3px 0 0 @brown-dark;

      svg {
        height: 16px;
        width: 16px;
        margin: 3px auto;
        display: block;
      }
    }
  }
}
