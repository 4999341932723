@import (once) url('../../../../../rootImports.less');

.text-button-comp {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-family: @lessonup-body-new;
  margin: 0;
  padding: 0;
  text-align: left;
  white-space: nowrap;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: underline;

  &.disabled {
    cursor: default;
    opacity: 0.6;
  }
}
