@import (once) url('../../../../../../rootImports.less');

@channel-logo-size: 40px;

.channel-info {
  display: flex;
  align-items: center;
  font-family: @lessonup-body-new;
  color: @default-text;

  .action-button {
    margin-left: 1em;
  }
}

.channel-info-link {
  margin-left: 0.6em;
  flex: 0.9;

  a {
    color: @cyan;
    font-weight: bold;
  }
}

.channel-info-logo {
  height: @channel-logo-size;
  width: @channel-logo-size;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
