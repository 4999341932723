@import (once) url('../../../../../rootImports.less');
@toast-height: 60px;
@toast-padding-collapsed: 0 50px 0 20px;
@toast-padding-show: 15px 50px 15px 20px;

.toast-provider {
  position: fixed;
  top: 5px;
  left: 5vw;
  right: 5vw;
  text-align: center;
  margin: auto;
  max-width: 750px;
  z-index: 10000;

  @media (min-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    min-width: 100px;
    padding: 8px 50px 6px 20px;
  }

  @media (max-width: 320px) {
    font-size: 14px;
  }

  .toast {
    height: @toast-height;
    padding: @toast-padding-show;
    position: relative;
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
    border-radius: 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
    .transition (all 0.3s ease-out);
    animation: scale-y 0.3s ease-in-out;
    div + div {
      margin-left: 10px;
    }
    .msg {
      line-height: 35px;
    }
    &.hide {
      animation: scale-y-reverse 0.3s ease-in-out;
      height: 0;
      padding: @toast-padding-collapsed;
    }

    &.focus {
      background-color: @pink;
      color: #fff;
    }
    &.green {
      background-color: lighten(@green, 5);
      color: #fff;
    }
    &.pink {
      background-color: @pink;
      color: #fff;
    }
    .close-toast {
      position: absolute;
      width: 30px;
      height: 30px;
      min-height: 30px; /* override default button style */
      background-size: 50% auto;
      background-repeat: no-repeat;
      background-position: center center;
      right: 10px;
      top: 50%;
      .translatey(-50%);
    }
    .actions {
      white-space: nowrap;
      .content-item-button + .content-item-button {
        margin-left: 8px;
      }
    }
  }
}

@keyframes scale-y {
  0% {
    height: 0;
    padding: @toast-padding-collapsed;
  }
  100% {
    height: @toast-height;
    padding: @toast-padding-show;
  }
}

@keyframes scale-y-reverse {
  0% {
    height: @toast-height;
    padding: @toast-padding-show;
  }
  100% {
    height: 0;
    padding: @toast-padding-collapsed;
  }
}
