@import (once) '_imports';

.spinner-container {
  color: transparent !important;
  background: url('@{assetsUrl}/img/rocket4.png') no-repeat center center;
  background-size: auto 60%;
  .animation (fly-small infinite 0.1s ease-in-out);
  pointer-events: none;
  cursor: default;
  &.slow {
    .animation (fly-small infinite 0.8s ease-in-out);
  }

  &.spinner-sizing-standard {
    height: 100px;
    width: 100px;
  }
}

#question .answer .nr.spinner-container {
  position: absolute;
  width: 10%;
  height: 100%;
  background-size: 100% auto;
  .transform(translateY(0));
  top: 0;
}

.answer4,
.answer2 {
  .spinner-container {
    right: 6%;
  }
}
.answer1,
.answer3 {
  .spinner-container {
    left: 6%;
  }
}

.student-button.spinner-container {
  background-size: auto 60%;
  pointer-events: none;
  cursor: default;
}
