@import (once) url('../../../../rootImports.less');

.image-tile-wrapper {
  width: 100%;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
  bottom: 0;

  .tile-svg {
    position: absolute;
    bottom: 0;
    right: -5%;
    height: 50%;
  }

  .image-tile {
    margin-bottom: 2rem;
  }

  @media (min-width: @mq-xs-min) {
    .image-tile .body {
      max-width: 80%;
      justify-content: space-between;
    }

    .tile-svg {
      bottom: -15%;
      height: 60%;
    }
  }

  @media (min-width: @mq-sm-min) {
    width: 45%;

    .tile-svg {
      bottom: -30%;
      right: -15%;
    }

    .image-tile {
      min-height: 250px;
      margin-bottom: 0;

      .body {
        max-width: 90%;
      }
    }
  }

  @media (min-width: @mq-md-min) {
    .image-tile {
      min-height: 300px;
    }

    .tile-svg {
      height: 80%;
      right: -30%;
    }
  }

  @media (min-width: @mq-lg-min) {
    .image-tile {
      min-height: 300px;
    }

    .tile-svg {
      height: 90%;
      bottom: -60%;
    }
  }
}

.image-tile {
  background-color: #fff;
  border-radius: 10px;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 2rem;
  border: 2px solid @outline;

  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.red .tile-title {
    color: @red;
  }

  &.blue .tile-title {
    color: @blue;
  }

  .tile-title {
    margin: 0;
  }

  .body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.stack > * {
      margin-top: 1.2rem;
    }
  }
}

.teacher-tile {
  .teacher-image {
    transform: scaleX(-1);
    bottom: 0;

    @media (min-width: @mq-sm-min) {
      transform: scaleX(1);
      left: -15%;
    }

    @media (min-width: @mq-md-min) {
      bottom: -5%;
      left: -20%;
    }

    @media (min-width: @mq-lg-min) {
      bottom: -40%;
      left: -30%;
    }
  }

  @media (min-width: @mq-sm-min) {
    &.align-end {
      align-items: flex-end;
      text-align: end;

      .action-button {
        align-self: flex-end;
      }
    }
  }
}
