@import (once) url('../../../../rootImports.less');

.lesson-thumb-page {
  position: fixed;
  font-size: 13px;
  display: flex;
  flex-direction: column;

  .lesson-summary-card__preview {
    position: relative;
  }
  .lesson-preview-tile--initialPin {
    overflow: hidden;
  }

  .lesson-preview-tile--lowerpin {
    overflow: hidden;
    position: relative;
    .pin-container {
      height: 400% !important;
      width: 400% !important;
      transform: scale(0.25) translateX(-150%) translateY(-150%);
    }
  }
}
