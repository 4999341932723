.pin-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .component-area {
    top: 0;
  }

  &#openQuestion {
    .placeholder-answers {
      z-index: unset;
    }
  }
}
