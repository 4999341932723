@import (once) url('../../../../../rootImports.less');

@lesson-card-size: 255px;
@lesson-card-margin: 5px;

@A4-paper-width-in-mm: 297mm;
@A4-paper-width-in-px: convert(@A4-paper-width-in-mm, 'px');
@slide-aspect-ratio: 16/9;
@slide-height: floor(@A4-paper-width-in-px / @slide-aspect-ratio);
@print-margin: 28px;
@slide-notes-width: @A4-paper-width-in-px - @print-margin*2;

.print-lesson-page-view {
  padding: 0;
  background: white;
  box-sizing: border-box;
  max-width: 100%;
  height: calc(@slide-height - 1px);
  .slide-page {
    page-break-after: always;
    overflow: hidden;

    .slide-wrapper {
      width: @A4-paper-width-in-px;
      height: @slide-height;
      height: calc(@slide-height - 1px);
      font-size: 60px;
      position: relative;
      .pin-view,
      .pin-view.thumbPreview {
        border: none;
      }
    }
    .video-url {
      font-size: 14px;
      margin: 0;
      background-color: @white;
      z-index: 5;
      bottom: 0;
      width: 100%;
      position: absolute;
      .play-icon {
        transform: translateY(5px);
        margin-right: 6px;
      }
    }
  }

  .slide-notes {
    width: @slide-notes-width;
    margin: 0 40px;

    h2 {
      font-family: 'montserrat';
    }

    .slide-note {
      display: inline-block;
      vertical-align: top;
      break-inside: avoid;
      width: @slide-notes-width / 2;
      margin-bottom: 16px;
    }
  }
}
