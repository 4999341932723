@import (once) url('../../../../../rootImports.less');

.suggestion_container {
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
}

.suggestion-content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.suggestion_modal {
  overflow: inherit;
}

.school_suggestion {
  display: flex;
  flex: 1 0 25%;
  max-width: 30%;
  flex-direction: column;
  border: 2px solid @black;
  border-radius: 5px;
  background: @white;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  width: 50%;
  margin: 0 10px 10px;

  &:hover {
    background: @grey-light2;
  }

  &.selected {
    background: @blue;
    cursor: auto;
  }
}

.school_suggestion_city {
  font-style: italic;
  font-size: 50%;
}

.spread_modal_buttons {
  justify-content: space-around;
}

.ReactModal__Overlay {
  z-index: 10000;
}

.pressed-teach-modal {
  overflow: inherit;

  .reset-promo-styling {
    text-transform: none;
    text-align: start;
  }
}
