@import (once) url('../../../../../../rootImports.less');

.registration-suggestions-page {
  max-width: 900px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: @lessonup-body-new;

  &__title {
    font-family: @lessonup-title;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: @spacer * 2;
  }

  &__description {
    p {
      margin: 0;
    }

    line-height: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: @spacer * 8;
  }

  &__card-content-span {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  &__cards-container {
    width: 100%;
    max-width: @mq-sm-min;
    margin-bottom: @spacer * 8;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .card-icon {
      margin-right: @spacer * 8;
    }
  }

  &__card-content-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    @media (max-width: @mq-xs-max) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__card-content {
    max-height: 100%;

    h2 {
      margin-top: 0;
      font-family: @lessonup-title;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: @spacer;
    }

    p {
      margin: 0;
    }
  }

  &__create-account-button {
    margin-bottom: @spacer * 2;
  }

  &__skip-button {
    margin-bottom: @spacer * 4;
  }

  &__divider {
    width: 100%;
    margin: 1rem 0;
    padding: 0;
    height: 1px;
    background-color: @surface-plus-2;
  }

  &__subtext {
    margin-top: @spacer * 4;
  }

  &__test {
    margin-top: @spacer * 8;
  }

  &__request-response-container {
    display: flex;
    align-items: center;

    svg {
      margin-right: @spacer * 2;
    }

    &__card-button {
      margin-left: auto;
    }

    &__request-response-container {
      display: flex;
      align-items: center;

      svg {
        margin-right: @spacer * 2;
      }

      &--error {
        color: @red-new;
      }

      &--success {
        color: @green-new;
      }
    }
  }
}
