@import (once) url('../../../../../rootImports.less');
@import (once) url('./LessonPlanRoot.less');

.one-column {
  .youtube-wrapper,
  .vimeo-wrapper {
    height: 200px;
  }
}

.two-columns {
  .youtube-wrapper,
  .vimeo-wrapper {
    height: 300px;
  }
}

.three-columns {
  .youtube-wrapper,
  .vimeo-wrapper {
    height: 450px;
  }
}

.lesson-plan-video {
  float: left;
  max-width: @column3;

  .vimeo-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 56.25% 0 0 0;
    position: relative;
    width: 100%;
  }

  .vimeo-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .youtube-embed {
    height: 100%;
    width: 100%;
  }
  .youtube-wrapper {
    width: 100%;
  }
  .youtube-thumb {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .icon {
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
    }
  }
}
