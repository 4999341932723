@import (once) url('../_imports.less');

#openQuestion {
  width: 100%;
  height: 100%;
  top: 0%;
  position: absolute;

  .question,
  #title,
  .text {
    position: absolute;
    // padding: 1%;
    top: 3%;
    left: 5%;
    height: 30%;
    width: 90%;
    right: 5%;
    bottom: 70%;
    text-align: center;
    font-family: @lessonup-title;
    line-height: 1.3em;
    overflow: hidden;
    z-index: 2;
    &.text-align-1 {
      text-align: center;
    }
    &.text-align-2 {
      text-align: left;
    }
    &.text-align-3 {
      text-align: right;
    }
  }

  .image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .placeholder-answers {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    top: 33%;
    left: 5%;
    height: 60%;
    width: 90%;
    z-index: 2;
  }

  &.preview {
    font-size: @preview-font;
  }
  .component-area {
    @height: 33%;
    @scaleup: 100 / @height*100;
    width: 100%;
    height: @height;
    position: absolute;
    overflow: hidden;
    .scale-up {
      height: @scaleup;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.thumbPreview {
  #openQuestion {
    .open-question-with-image-camera-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-image: url('@{assetsUrl}/img/icons/black/camera.png');
      background-size: 20%;
      background-repeat: no-repeat;
      background-position: center 62%;
    }
  }
}
