@import (once) url('../_imports.less');

@media (max-width: 590px) {
  html {
    #site {
      .wide {
        display: none !important;
      }
      .small {
        display: inherit !important;
      }
      .panel {
        padding: 40px 20px;
        h1 {
          font-size: 50px;
        }
        h2 {
          font-size: 30px;
        }
        form.beta {
          box-shadow: none;

          input[type='text'] {
            display: block;
            border-radius: 5px 5px 0 0;
            margin-bottom: 10px;
            width: 75%;
            margin: 0px auto;
            text-align: center;
          }
          input[type='submit'] {
            display: block;
            border-radius: 0 0 5px 5px;
            margin-bottom: 10px;
            width: 75%;
            margin: 0px auto;
            text-align: center;
          }
        }
      }
    }
  }
}

@media (min-width: 800px) and (min-height: 500px) {
  html.chrome,
  html.safari {
    #site {
      .panel {
      }
    }
    .vert {
      /*			position: absolute;
			top: 50%; left: 50%;
			-webkit-transform: translate(-50%, -50%);
*/
    }
  }
}

#site {
  font-family: @lessonup-body;

  .small {
    display: none !important;
  }

  .panel {
    position: relative;
    width: 100%;
    min-height: 400px;
    padding: 50px 30px;
    text-align: center;

    .body {
      display: inline-block;
      max-width: 600px;
    }

    &.fg-black {
      h3,
      p {
        color: rgba(0, 0, 0, 0.8) !important;
      }
    }

    h1 {
      font-family: @lessonup-title;
      font-size: 60px;
      font-weight: normal;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    }
    h2 {
      font-weight: normal;
      margin: 40px 0;
      line-height: 40px;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    }
    h3 {
      font-size: 30px;
      font-family: @lessonup-round;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 10px;
    }
    hr {
      display: block;
      width: 200px;
      height: 8px;
      margin: auto;
      border: none;
      outline: none;
      background: transparent no-repeat center center;
      background-size: 100% auto;
      margin-bottom: 30px;

      &.dash {
        background-image: url(/site/hr.png);
        margin-bottom: 50px;
      }
    }
    p {
      font-size: 20px;
      line-height: 32px;
      text-align: left;
      color: rgba(0, 0, 0, 0.8);
    }

    form.beta {
      display: block;
      font-size: 0;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

      input[type='text'],
      input[type='email'] {
        padding: 0 20px;
        border-radius: 5px 0 0 5px;
      }

      input[type='submit'] {
        background: @pink;
        padding: 0 20px;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
      }
    }

    &.bg-pink {
      hr {
        background-image: none;
      }
      h3,
      p {
        color: rgba(255, 255, 255, 0.9);
      }
    }

    &.visual {
      color: #fff;
      background: #fff url(/site/bg3_800.jpg) no-repeat center center;
      background-size: cover;
    }
  }
}

#feelsize {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  pointer-events: none;
}
