@import (once) url('../../../../../rootImports.less');

.modal__glasspane {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001; // We should create a sperate z-index file, cause this is nuts
}

.ReactModal__Body--open.legacy-modal-open {
  overflow: hidden;
}

.ReactModal__Content {
  outline: none;
}

.modal {
  .modal-width;

  background-color: @grey;
  border-radius: 0;
  overflow: hidden;
  max-width: 700px;
  position: relative;

  .modal__title {
    height: @modal-title-height;
    padding: 0 20px 0 20px;
    background: @yellow;
    overflow-y: hidden;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal__title_text {
    color: @default-text;
    font-size: 24px;
    font-weight: 400;
    font-family: @lessonup-logo;
    text-transform: none; // Needed due to some global styles in Teacher.
  }

  &.increase-title-size .modal__title {
    height: @modal-title-height * 1.3;
  }

  &.theme-red {
    .modal__title {
      background: @pink;
      color: #fff;

      .modal__title_text {
        color: #fff;
        margin: 0;
        font-size: 28px;
      }
    }
  }

  .modal__title_close {
    fill: @default-text;
    cursor: pointer;
    height: 16px;
    width: 16px;
  }

  .modal__content {
    font-family: @lessonup-body-new;
    padding: @modal-content-padding @modal-content-padding;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;
    outline: none;
  }

  h3 {
    font-family: @lessonup-logo;
  }

  .modal-bottom-text {
    font-size: 11px;
    margin-top: 1em;
    margin-bottom: 0;
  }
}

.modal__button-bar {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > * {
    margin-bottom: 0.6rem;
  }

  &.right {
    justify-content: flex-end;
  }
}

@media (min-width: @mq-xs-min) {
  .modal__button-bar {
    flex-direction: row;
  }
}

@media screen and (min-width: 850px) {
  .modal__button-bar {
    &.b-b-0 {
      & > :first-child {
        position: absolute;
        left: 0;
      }
    }

    &.center {
      /* last-child if no. of children > 1 */
      .spacing-left {
        margin-left: 0.6rem;
      }
    }
  }
}

@media screen and (max-width: 651px) {
  .modal {
    max-width: 100vw;
    margin: auto;

    .modal__content {
      height: calc(100vh - (@modal-title-height + @modal-content-padding));
      padding-bottom: 50px;
      max-height: unset;
      overflow-y: unset;
    }
  }

  .modal__glasspane {
    align-items: flex-start;
  }

  .ReactModalPortal .ReactModal__Content.slide-from-bottom {
    transition: all 0.5s ease-in-out;
    transition-delay: 0.1s;
    transform: translateY(1000px);

    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {
      transform: translateY(1000px);
    }
  }
}

.modal-width {
  width: auto;
  &.modal--small {
    width: 300px;
  }
  &.modal--medium {
    width: 500px;
  }
  &.modal--large {
    width: 700px;
  }
}
