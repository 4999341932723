@import (once) url('../../../../../rootImports.less');
@import (once) url('./LessonPlanRoot.less');

.lesson-plan-attachment {
  float: left;
  .plan-attachment {
    width: @column;
    margin: 0;
    color: @default-text;
    .font-montserrat;

    &.type-image .icon {
      background-image: url(/img/icons/white/file-picture.png);
    }
    &.type-audio .icon {
      background-image: url(/img/icons/white/file-music.png);
    }
    &.type-pdf .icon {
      background-image: url(/img/icons/white/file-pdf.png);
    }
    &.type-powerpoint .icon {
      background-image: url(/img/icons/white/file.png);
    }
    &.type-attachment .icon {
      background-image: url(/img/icons/white/file.png);
    }
    &.type-document .icon {
      background-image: url(/img/icons/white/file.png);
    }

    .info {
      padding: 20px;
      text-align: center;
      .type {
        color: @grey-mid-2;
      }
    }
    &.has-image {
      height: @rowLesson;
      .visual {
        width: 100%;
        height: 144px;
        margin-bottom: 12px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }
}
