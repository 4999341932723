@import (once) url('../../../../../rootImports.less');
@import (once) url('./pin-mixins.less');

.pin-view,
.pin-view.thumbPreview {
  width: inherit;
  height: inherit;
  font-size: inherit;
  box-shadow: none;
  border: 1px solid @border;
}

@sizes: 175, 200, 225, 250, 260, 275, 300, 350, 400, 500, 600, 1000;

.size-loop (@i) when (@i > 0) {
  @width: extract(@sizes, @i);
  @min-width: extract(@sizes, @i); // Workaround for IE11.
  .pin-size-@{width} {
    .pin-size(@width, 1);
  }
  .pin-font-size-@{width} {
    .pin-font-size(@width, 1);
  }
  .size-loop(@i - 1);
}
.size-loop(length(@sizes));

.pin-view-scaler {
  width: 160px;
  height: 90px;
  .pin-view {
    transform: scale(0.74);
    transform-origin: top left;
    width: @preview-width;
    height: @preview-height !important;
    font-size: @preview-font;
  }
}

.pin-video-view {
  right: -25px;
  top: 13.5px;
  .video-slide-thumb {
    z-index: 3;
    top: -15px;
    left: -25px;
    background-color: black;
    position: absolute;
    width: 78px;
    height: 45px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .video-slide-time {
    text-align: center;
    position: absolute;
    background-color: @brown-dark;
    padding: 2px;
    color: white;
    bottom: -10px;
    height: 20px;
    width: 100%;
  }
  .has-end {
    font-size: 75%;
    line-height: 1.8em;
  }
  .arrow {
    width: 15px;
    position: absolute;
    top: 44px;
    left: -20px;
    stroke: @grey-light2;
  }
}
