@import (once) url('_imports.less');

#player .content.youtube,
#player .content.vimeo {
  overflow: hidden;
}

.video-controller {
  .popup-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-popup {
    padding: 20px 40px 40px 40px;
    font-size: 30%;
    background: @blue;
    color: #fff;
    border-radius: 5px;
    min-height: 100px;
    pointer-events: all;
  }
  .video-bar {
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: gray;
    .video-bar-inner {
      position: absolute;
      left: 0;
      height: 100%;
      background-color: #77a3ae;
    }
    .bar-start-stop {
      position: absolute;
      background-color: @red;
      width: 5px;
      height: 100%;
    }
    .bar-question-small-indicator {
      position: absolute;
      background-color: @yellow;
      width: 5px;
      height: 100%;
    }
  }
  .overlay-wrapper {
    #slide {
      background: transparent;
    }
  }
}

.video-keep-in-dom {
  &.show-true {
    height: 100%;
  }
  &.show-false {
    height: 0;
    opacity: 0;
  }
}
