@import (once) url('../../../../../rootImports.less');

@border-radius-searchform: 26px;

.searchform_common {
  font-family: @lessonup-body-new;
  padding-top: 10px;
  padding-bottom: 10px;

  font-size: inherit;

  background-color: white;
  border: none;
  outline: none;

  &:focus {
    text-decoration: underline;
  }
}

.searchform {
  display: flex;
  flex-direction: row;

  * {
    font-family: @lessonup-body-new;
  }
}

.searchform__input {
  &:extend(.searchform_common);
  flex: 1 1 auto;
  min-width: 100px;
  padding-left: 20px;
  border-top-left-radius: @border-radius-searchform;
  border-bottom-left-radius: @border-radius-searchform;
  border: 1px solid transparent;
  padding-right: 0;
  margin-right: 0;

  &:focus {
    border-color: @blue-dark;
  }

  // Styles the search icon that Safari inserts as well as the cancel search button on Chrome and Safari.
  &::-webkit-search-results-button,
  &::-webkit-search-cancel-button {
    margin-right: 0.4em;
    cursor: pointer;
  }

  &::placeholder {
    overflow: visible;
  }

  &:not(:focus):placeholder-shown::-webkit-search-results-button {
    // Safari only. Styles the search results button that Safari provides.
    display: none;
  }
}

.searchform__button {
  &:extend(.searchform_common);
  width: 50px;
  flex-shrink: 0;
  background-color: @blue;
  border-top-right-radius: @border-radius-searchform;
  border-bottom-right-radius: @border-radius-searchform;
  cursor: pointer;

  &:hover {
    background-color: darken(@blue, 15);
  }

  &:active {
    background-color: darken(@blue, 25);
  }
}

.searchform__icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  fill: @white;
}
