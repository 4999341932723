@import (once) url('../../../../../rootImports.less');

.btn-new {
  position: relative;
  display: inline-block;
  line-height: 1.7em;
  padding: 0 1em;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.2em;
  &.btn-round {
    border-radius: 2em;
  }
  &.btn-big {
    font-size: 115%;
    font-weight: bold;
    line-height: 3em;
  }
  &.btn-underline {
    text-decoration: underline;
  }
  &.icon {
    background-repeat: no-repeat;
    background-position: 7px center;
    background-size: 15px auto;
    padding-left: 32px;
  }
  svg {
    position: absolute;
    left: 0.5em;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &:hover {
    text-decoration: underline;
  }

  &.link-button--small {
    line-height: @small-button-height;
  }
  &.link-button--medium {
    line-height: @medium-button-height;
  }
  &.link-button--large {
    line-height: @large-button-height;
  }
}
