@import (once) url('../../../../../rootImports.less');

.login-modal {
  .loginmodal-buttons {
    display: flex;
    flex-direction: column;

    .action-button {
      margin-bottom: 0.6em;
    }

    @media (min-width: @mq-xs-min) {
      flex-direction: row;
      justify-content: space-around;

      .action-button {
        margin-bottom: 0;
      }
    }
  }
}
