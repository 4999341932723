@import (once) url('../../../../../../../rootImports.less');

.condensed-explorer-table {
  &__table {
    width: 100%;
    tr:hover {
      cursor: pointer;
      background-color: @wild-sand;
    }
  }
}
