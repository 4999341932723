@import (once) url('../../../../../rootImports.less');

@arrow-size: 16px;
@arrow-offset: -11px;
@minimum-width: 150px;
@y-translation-top: 42px;
@y-translation-bottom: -24px;
@x-translation-sides: 12%;
@border-width: 2px;

//top center is default orientation and position
.tooltip(@bg-color) {
  position: absolute !important;
  border: @border-width solid @white;
  padding: 15px;
  filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.3));
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  text-align: center;
  z-index: 1;
  font-size: 0.7rem;
  font-weight: normal;
  border-radius: 10px;
  background-color: @bg-color;
  min-height: calc(2 * @arrow-size);
  min-width: @minimum-width;
  margin-bottom: @arrow-size;
  pointer-events: auto;

  &.inline {
    white-space: nowrap;
    min-width: auto;
  }

  //arrow - rotated square
  &:before {
    content: '';
    width: @arrow-size;
    height: @arrow-size;
    transform: rotate(225deg);
    border-top: @border-width solid @white;
    border-left: @border-width solid @white;
    bottom: @arrow-offset;
    position: absolute;
    background-color: @bg-color;
  }

  &.orientation-top {
    bottom: 100%;
    margin-bottom: @arrow-size;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    &:before {
      bottom: @arrow-offset;
      transform: rotate(225deg);
      left: 46%;
    }
  }

  &.orientation-bottom {
    top: 100%;
    left: 50%;
    margin-top: @arrow-size;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    &:before {
      top: @arrow-offset;
      transform: rotate(45deg) translate(-50%, 50%);
      -webkit-transform: rotate(45deg) translate(-50%, 50%);
      left: ~'calc(50% + (@{border-width} * 2))';
    }
  }

  &.orientation-right {
    bottom: 50%;
    left: 100%;
    margin-left: @arrow-size;

    -webkit-transform: translateY(65%);
    transform: translateY(65%);

    &:before {
      top: calc(50% - @arrow-size / 2);
      transform: rotate(-45deg);
      left: @arrow-offset;
    }
  }

  &.orientation-left {
    bottom: 50%;
    right: 100%;
    margin-right: @arrow-size;
    -webkit-transform: translateY(65%);
    transform: translateY(65%);

    &:before {
      top: calc(50% - @arrow-size / 2);
      transform: rotate(135deg);
      right: @arrow-offset;
      left: auto;
    }
  }

  .direction;

  /* Some combinations don't work well with the default values */
  &.orientation-bottom.direction-right {
    &:before {
      left: 21px;
    }
  }
}

.tooltip {
  &.tooltip--yellow {
    .tooltip(@yellow);
    color: @default-text !important;
  }

  &.tooltip--cyan {
    .tooltip(@cyan);
    color: @default-text !important;
  }

  &.tooltip--dark {
    .tooltip(@black);
    color: @white !important;
  }

  &.tooltip--light {
    .tooltip(@white);
    color: @default-text !important;
  }

  .direction() {
    &.direction-left {
      right: 50%;
      left: auto;
      -webkit-transform: translateX(@x-translation-sides);
      transform: translateX(@x-translation-sides);

      &:before {
        left: auto;
        right: @arrow-size;
      }
    }

    &.direction-right {
      left: 50%;
      right: auto;
      -webkit-transform: translateX(-@x-translation-sides);
      transform: translateX(-@x-translation-sides);

      &:before {
        right: auto;
        left: @arrow-size;
      }
    }

    &.direction-top {
      top: auto;
      bottom: 50%;
      -webkit-transform: translateY(@y-translation-top);
      transform: translateY(@y-translation-top);

      &:before {
        bottom: @arrow-size;
        top: auto;
      }
    }

    &.direction-bottom {
      top: 50%;
      bottom: auto;
      -webkit-transform: translateY(@y-translation-bottom);
      transform: translateY(@y-translation-bottom);

      &:before {
        top: @arrow-size;
      }
    }
  }
}

.tipped > .passive-tip-wrapper {
  visibility: hidden;
}

// TODO: Cleanup CSS and see if there is a better way than transition to keep the tooltip open when the mouse moves towards it.
.tipped > .passive-tip-wrapper.interactive-content {
  // We add a transition to keep the tooltip open if a user moves their mouse onto the tooltip.
  // This is to prevent the tooltip from closing immediately.
  transition: visibility 2s ease-in;
}

.tipped:hover > .passive-tip-wrapper,
.passive-tip-wrapper:hover {
  visibility: visible;
  .tooltip {
    opacity: 1;
  }
}
