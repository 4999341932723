@import (once) url('../_imports.less');

@border-size: 0.05em;
@header-border-size: 0.1em;
@border-color: black;

[data-component='table'] {
  .outline {
    pointer-events: none;
  }
}

.table-react-component {
  width: 100%;
  &:hover {
    cursor: text;
  }
  [contenteditable] {
    border: 0 !important;
  }
  [contenteditable]:focus {
    outline: 0px solid transparent;
  }
  .ct-row {
    position: relative;
    display: flex;
  }

  .cell-wrapper {
    vertical-align: top;
    position: relative;
    &.no-border {
      border: none !important;
    }
    // top
    &.b-t {
      border-top: @border-size solid currentColor;
    }
    &.b-t-h {
      border-top: @header-border-size solid currentColor;
    }
    // bottom
    &.b-b {
      border-bottom: @border-size solid currentColor;
    }
    &.b-b-h {
      border-bottom: @header-border-size solid currentColor;
    }
    // left
    &.b-l {
      border-left: @border-size solid currentColor;
    }
    &.b-l-h {
      border-left: @header-border-size solid currentColor;
    }
    // right
    &.b-r {
      border-right: @border-size solid currentColor;
    }
    &.b-r-h {
      border-right: @header-border-size solid currentColor;
    }
  }
  &.border-black .cell-wrapper {
    border-color: black;
  }
  &.border-white .cell-wrapper {
    border-color: white;
  }

  .ct-cell {
    border: none !important;
    padding: 0.2em;
    overflow: hidden;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    // In Firefox content editable has no height when there is no content so we put in an invisible character
    &:empty {
      &::before {
        content: '\feff';
      }
    }

    p {
      margin-top: 0; // Required for IE 11 which turns line breaks into paragraphs.
    }
  }

  .cell-menu {
    width: 0;
    height: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;

    @media (hover: none) {
      opacity: 1;
    }
  }

  .cell-wrapper:hover {
    .cell-menu {
      opacity: 1;
    }
  }

  .cell-menu:hover {
    cursor: pointer;
  }

  .cell-menu-toggle {
    width: 13px;
    height: 7px;
    transform: translateX(-50%) translateY(100%);
    right: 0;
    position: absolute;
  }

  .table-cell-menu {
    position: absolute;
    right: 26px;
    background-color: @white-2;
    z-index: 100;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 200px;
    padding: 6px 0px;
    border-radius: 4%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transform: translateY(-93%);

    &.table-cell-menu-flip-x {
      right: unset;
    }

    &.table-cell-menu-flip-y {
      transform: unset;
    }
  }

  .table-cell-menu-button {
    width: 100%;
    height: 38px;
    display: flex;
    padding: 10px 0px;

    &:hover {
      background-color: @grey-bg;
    }
  }

  .table-cell-menu-icon {
    margin: 0px 12px 0px 14px;
    height: 100%;
    pointer-events: none;
  }

  .table-cell-menu-text {
    margin: 1px 0px;
    font-size: 12px;
    font-weight: bold;
    color: @brown-dark;
    pointer-events: none;
  }

  .table-cell-menu-devider {
    height: 0px;
    width: 185px;
    outline: 1px solid @grey-bg;
    margin: -1px 0px -1px 14px;
  }

  .table-toggle-off {
    opacity: 0.5;
  }
}
