@import (once) url('../../../../../../rootImports.less');
@import (once) url('../../pin/pin-mixins.less');

@spacing: 10px;
@slide-info-height: 60px;
@player-nav-height: 30px;

.responsive-slide-sizing(@screenwidth, @width, ) {
  @media only screen and (max-width: @screenwidth) {
    width: @width;
  }
}

.lesson-preview {
  outline: none;
  color: @brown-dark;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: @mq-xs-max) {
    flex-direction: column;
  }

  .lesson-preview__player-container {
    align-self: flex-start;
    background-color: @white-2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: @slide-info-height + @player-nav-height;
    width: 640px;

    .responsive-slide-sizing(@mq-lg-min + 1, 500px);

    .responsive-slide-sizing(@mq-sm-max, 400px);

    .responsive-slide-sizing(@mq-xs-max, 100%);
  }

  .lesson-preview__player {
    flex: 0 0 auto;
    background-color: #000;
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    width: 100%;
    border: 1px solid @white-2;
    background-color: @white-2;

    .user-select(none);

    @media (min-width: @mq-sm-min) {
      .pin-font-size(400, 1);
    }

    @media (min-width: @mq-md-min) {
      .pin-font-size(500, 1);
    }

    @media (min-width: @mq-lg-min) {
      .pin-font-size(640, 1);
    }
  }

  .lesson-preview__player-nav {
    @height: @player-nav-height;

    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    bottom: calc(-@height - 16px);
    height: @height;
    width: 330px;

    font-weight: 600;
    max-width: 80vw;
    transform: translateX(-50%);
    background: @cyan;
    border-radius: 500px;
    box-shadow: 0px 0px 0px 2px @white-2;
  }

  .player-nav-button {
    width: ~'calc(100% / 3)';
    display: inline-flex;

    &.clickable {
      cursor: pointer;

      &:hover .navigation-text-button {
        text-decoration: underline;
      }
    }

    &.player-nav-button-right {
      justify-content: flex-end;

      .navigation-text-button {
        margin-right: 0.4em;
      }
    }

    &.player-nav-button-left {
      .navigation-text-button {
        margin-left: 0.4em;
      }
    }
  }

  .navigation-text-button {
    color: #fff;
    font-size: 13px;
    margin: auto;
  }

  .lesson-preview__player-navbutton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 26px;
    height: 26px;
    margin: 2px;
    background: white;
    border-radius: 100%;
    cursor: pointer;
    outline: 0;
    color: @brown-dark;
    &:active {
      background-color: @grey-light;
    }

    .arrow-left {
      margin-left: auto;
      margin-right: 9px;
    }

    .arrow-right {
      margin-left: 9px;
      margin-right: auto;
    }
  }

  .lesson-preview__player-nav-count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: white;
  }

  .lesson-preview__slide-info {
    position: absolute;
    bottom: -80px;
    width: 100%;
    text-align: center;
    font-weight: normal;
    .ellipsis;
  }

  .lesson-preview__content {
    box-sizing: border-box;
    padding-left: @spacing;
    display: block;
    flex: 1;

    @media only screen and (max-width: @mq-xs-max) {
      margin-top: 60px;
      padding-left: 0;
      order: 1;
    }
  }
}
