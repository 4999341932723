@import (once) url('../../../../../../rootImports.less');
@import (once) url('../../../../../shared-core/ui/components/pin/PinFlow.less');

// copy of lesson-overview.less with just tweaks for the v2 page
// you will find the rest of the styles in lesson-overview.less
.lesson-overview.v2 {
  color: @brown-dark;

  .lesson-overview__title {
    margin-top: 20px;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
      font-size: 6vw;
    }
  }

  .print-button {
    display: flex;

    svg {
      height: 1rem;
      width: 1rem;
      margin-right: 0.4rem;
    }
  }
}

.pin-flow-40-v2 {
  .pin-flow(40);
  .pin-wrapper {
    display: inline-block;
  }
}

.lesson-preview__pin-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
