@import (once) url('../../../../../../../rootImports.less');

.multiplechoice-wrapper {
  display: -ms-grid;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  color: @default-text;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;

  .option {
    margin: 0 auto;
    border-radius: 0.3em;
    padding: 10% 5%;
    text-align: center;
    word-break: break-word;
    display: flex;
    width: 100%;
    background-color: white;
    display: -ms-flexbox;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-item-align: stretch;
    align-self: stretch;

    &.clickable {
      cursor: pointer;
      opacity: 0.75;
    }
    &.active {
      border: 3px solid @pacific-blue;
    }

    &:hover,
    &.not-transparent,
    &.active {
      opacity: 1;
    }
  }

  .image-option {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    min-width: 150px;
    height: 150px;

    &.mc-image-contain {
      border: 0.1em solid #fff;
      background-size: contain;
    }

    &.active {
      border: 3px solid @pacific-blue;
    }

    &.empty {
      background-size: 1.4em;
      background-color: rgba(255, 255, 255, 0.6);
      background-blend-mode: overlay;
      background-position: center center;
    }
  }
  .text-option {
    flex-direction: column;
    white-space: pre-wrap;
    border: solid @dark-grey 1px;
    border-radius: 10px;
    overflow: hidden;
  }
  .create-option {
    cursor: pointer;
    border: 2px solid #fff;
    background-color: transparent;
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;

    &:focus {
      box-shadow: 2px 2px 2px 0 @blue;
    }
  }
}

.mc-wrapper-10 {
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-columns: repeat(auto-fit, minmax(19%, 19%));
  grid-auto-rows: 1fr;

  // Overrides dictate a size from which we never plan to deviate. Instead we put the container in charge of grid element resizing.
  &:not(.teacher-view) {
    @media (max-width: @mq-md-min) {
      grid-template-columns: repeat(3, 1fr);
      -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
    }

    @media (max-width: @mq-md-min) {
      grid-template-columns: repeat(3, 1fr);
      -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
    }
    @media (max-width: @mq-sm-min) {
      grid-template-columns: repeat(2, 1fr);
      -ms-grid-columns: 1fr 10px 1fr;
    }
    @media (max-width: @mq-xs-min) {
      font-size: 1.5em;
      grid-template-columns: repeat(1, 1fr);
      -ms-grid-columns: 1fr;
    }
  }
}
.edit-multiple-choice.mc-wrapper-10 {
  grid-template-columns: repeat(5, 1fr);
}

.mc-wrapper-8 {
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  &:not(.teacher-view) {
    @media (max-width: @mq-md-min) {
      grid-template-columns: repeat(3, 1fr);
      -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
    }
    @media (max-width: @mq-sm-min) {
      grid-template-columns: repeat(2, 1fr);
      -ms-grid-columns: 1fr 10px 1fr;
    }
    @media (max-width: @mq-xs-min) {
      font-size: 1.5em;
      grid-template-columns: repeat(1, 1fr);
      -ms-grid-columns: 1fr;
    }
  }
}
.mc-wrapper-6 {
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  &:not(.teacher-view) {
    @media (max-width: @mq-sm-min) {
      grid-template-columns: repeat(2, 1fr);
      -ms-grid-columns: 1fr 10px 1fr;
    }
    @media (max-width: @mq-xs-min) {
      font-size: 1.5em;
      grid-template-columns: repeat(1, 1fr);
      -ms-grid-columns: 1fr;
    }
  }
}

.mc-wrapper-4 {
  -ms-grid-columns: 1fr 10px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  &:not(.teacher-view) {
    @media (max-width: @mq-xs-min) {
      font-size: 1.5em;
      grid-template-columns: repeat(1, 1fr);
      -ms-grid-columns: 1fr;
    }
  }
}
.mc-wrapper-1 {
  -ms-grid-columns: 1fr 10px;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
}

.teacher-view .image-option {
  margin: 0;
}
.teacher-view .option {
  margin: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .multiplechoice-wrapper {
    display: block;
    width: 100%;
    color: @default-text;
    .option,
    .image-option {
      float: left;
      margin: 10px 10px 0 0;
      padding: 0;
      height: 150px;
      width: 23%;
      @media (max-width: @mq-md-min) {
        width: 30%;
      }
      @media (max-width: @mq-sm-min) {
        width: 47%;
      }
      @media (max-width: @mq-xs-min) {
        width: 100%;
      }
    }
    .option {
      text-align: center;
      padding: 1%;
      overflow: hidden;
    }
  }
}
