@import (once) url('../../../../../../../rootImports.less');

.breadcrumb {
  color: @grey-mid-3;
  white-space: nowrap;
  &__button {
    font-size: 1.3rem;
    font-weight: 800;
    font-family: @lessonup-body-new;
    &:hover {
      text-decoration: underline;
    }
    &--current {
      color: @brown-dark;
      font-weight: 700;
    }
    &--current,
    &--disabled {
      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }
    &--bigger {
      font-size: 1.8rem;
    }
  }
}
