@import (once) url('_imports.less');

@tooltip-arrow-size: 15px;

.button.disabled .button-tip {
  display: none !important;
}

.button-tip {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 20px;
  white-space: nowrap;
  padding: 15px 20px 10px 20px;
  line-height: 26px;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
  font-family: @lessonup-body-new;
  font-size: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  .opacity (0);
  z-index: 1001; /* one more than #page-bar-buttons */
  text-transform: none;
  max-width: 400px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  .user-select (none);
  .transition (all 0.3s ease-out);

  &.fixed-tip {
    opacity: 1;
  }

  &--center-arrow {
    margin-right: @tooltip-arrow-size / -2;
  }

  &.yellow {
    background-color: @yellow;

    &:after {
      border-bottom: @tooltip-arrow-size solid @yellow;
    }
    &.top {
      &:after {
        bottom: auto;
        border-bottom: none;
        border-top: @tooltip-arrow-size solid @yellow;
      }
    }
  }

  &.wrap {
    white-space: normal;
  }

  &:after {
    bottom: 99%;
    right: 10px;
    content: '';
    position: absolute;
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: @tooltip-arrow-size solid #fff;
  }

  &.top {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    &:after {
      bottom: auto;
      top: 100%;
      border-bottom: none;
      border-top: 15px solid #fff;
    }
  }
  &.left {
    right: auto;
    left: 0;
    &:after {
      right: auto !important;
      left: 10px;
    }

    &--center-arrow {
      margin-left: @tooltip-arrow-size / -2;
    }
  }
  &.center {
    right: auto;
    left: 50%;
    .transform(translateX(-50%));
    &:after {
      right: auto;
      left: 50%;
      .transform(translateX(-50%));
    }
  }
  &.show {
    display: inline-block;
    position: static;
    opacity: 1 !important;
  }

  pre {
    font-family: 'Monaco', 'Consolas', 'Courier New', Courier, monospace;
  }

  .shortcut {
    margin-top: 6px;
    font-size: 11px;
    text-align: center;
    .key {
      display: inline-block;
      background: rgba(0, 0, 0, 0.3);
      padding: 0 10px;
      color: #fff;
      text-transform: uppercase;
      border-radius: 3px;
      &.nr {
        font-family: 'Monaco', 'Consolas', 'Courier New', Courier, monospace;
      }
    }
    .plus {
      margin: 0 4px;
    }

    &.inline {
      display: inline-block;
      margin: 0 2px;
      .translatey(-3px);
    }
  }
  .dismiss {
    font-size: 50%;
    pointer-events: all;
    text-align: right;
  }
}
.button,
.tipped {
  &.disabled .button-tip {
    .opacity (0);
  }
}

.no-touch {
  .button,
  .tipped {
    &:hover > .button-tip {
      .opacity (1);
      .transition (all 0.3s ease-out 0.3s);

      &.delay {
        .transition (all 0.3s ease-out 1s);
      }
      &.no-delay {
        .transition (all 0.3s ease-out);
      }
    }
  }
}

.show-on-start {
  opacity: 1;
  animation: transition-point-top infinite 1.5s linear;
}
