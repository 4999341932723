@import (once) url('../_imports.less');

#slide {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  .overflow-hidden;

  /*
		.text are the classic text1 and text2,
		.text-value is the component text
	*/
  .text,
  .text-value {
    &.text-align-1 {
      text-align: center;
    }
    &.text-align-2 {
      text-align: left;
    }
    &.text-align-3 {
      text-align: right;
    }
    ul,
    ol {
      list-style-position: inside;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;

      & > br {
        display: none;
      }
    }
    p {
      padding-left: 0;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      margin: 0; /* make p's seem like BRs */
    }
    li {
      line-height: inherit;
    }
    font {
      font-size: inherit !important;
    }

    & > li {
      display: inline;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .text {
    display: block;
    position: absolute;
    text-align: center;
    resize: none;
    font-family: @lessonup-body;
    * {
      font-family: inherit !important;
      font-size: inherit !important;
    }
    .wrapper {
      width: 100%;
      height: 100%;
      padding: 0;
    }
    .text-inner {
      width: 100%;
      height: 100%;
      padding: 2%; /* for view-mode only */
    }
  }

  .text1 {
    font-family: @lessonup-title;
    line-height: 1.1;
    /* never render lists in text1 */
    li,
    ul,
    ol {
      display: inline;
      padding: 0;
      margin: 0;
    }
  }

  .text2 {
    line-height: 1.6;
  }

  .image {
    position: absolute;
    /* make the image full screen background by default */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .image2 {
    position: absolute;
    /* make the image full screen background by default */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: none;
  }
}

#slide,
#question,
#openQuestion {
  .image-placeholder-text {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    .icon {
      background-color: transparent;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
    }
  }
}

#slide.bullets-animatable.slide-animate-list {
  #text2,
  #text1,
  .component-text {
    li {
      opacity: 0;
      &.appear {
        opacity: 1;
        .transition(opacity 0.3s ease-out);
      }
    }
  }
}

/*
	This CSS replaces actual bullets with inline custom bullets and css-generated numbered lists
	(thus fixing the spacing issues with current Chrome versions)
*/
#playercontent,
.thumbPreview,
.component-text,
.text {
  li {
    line-height: inherit !important;
    margin: 0;
    padding: 0;
  }
  ul {
    padding-left: 1.1em !important;
    li {
      text-indent: -0.4em;
      position: relative;
      list-style: none;

      &:before {
        content: '\25CF';
        padding-right: 0.5em;
        display: inline-block;
        text-align: right;
      }

      &.no-list-style:before {
        content: '';
        padding-right: 0;
      }
    }
  }
  ol {
    padding-left: 1.5em !important;
    counter-reset: ol-counter;
    li {
      text-indent: -1.2em;
      counter-increment: ol-counter;
      position: relative;
      list-style: none;
      &:before {
        position: relative;
        content: counter(ol-counter) + '.';
        padding-right: 0.2em;
        display: inline-block;
        width: 1em;
        text-align: right;
      }
    }
  }
}
