@pin-font-ratio: 0.056px;

// all the pins have a border
.pin-font-size(@width, @border-width) {
  font-size: (@width - @border-width * 2) * @pin-font-ratio;
}

.pin-size(@width, @border-width) {
  width: @width * 1px;
  height: @width / 16 * 9px;
  .pin-font-size(@width, @border-width);
}

.pin-sizing(@pin-size, @pin-video-size, @pin-view-border, @screen-width ) {
  @media (min-width: @screen-width) {
    .pin-wrapper,
    .pin-view {
      .pin-size(@pin-size, @pin-view-border);
    }
    .pin-video-view {
      .pin-size(@pin-video-size, @pin-view-border);
    }
  }
}
