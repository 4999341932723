.limit-to-parentsize {
  max-width: 100%;
  max-height: 100%;
}

.background-image-fill-parent {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}

.background-image-fill-parent.cover {
  background-size: cover;
}

.background-image-fill-parent.contain {
  background-size: contain;
}

.limit-image-width {
  max-width: 55%;
}

.align-content-left {
  margin-left: 0;
  margin-right: auto;
  background-position: left;
}

.align-content-right {
  margin-left: auto;
  margin-right: 0;
  background-position: right;
}

.center-horizontal-translate {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.align-content-right-float .button {
  float: right;
}

.size-to-30-vh {
  height: 30vh;
  width: 30vh;
}

.basic-image-as-background {
  background-size: contain;
  background-repeat: no-repeat;
}

.align-right-block {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.align-center-margins {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.thumbPreview .hide-in-thumb {
  display: none !important;
}

.subpixel-position-fix-right {
  margin-right: -0.3px !important;
}

.disabled-simple {
  opacity: 0.6;
  pointer-events: none;
}

.page-center {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
