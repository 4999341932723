@import (once) url('../../../../rootImports.less');

.voucher-input-access {
  margin-top: 20px;
}

.redeem-voucher-inner-page {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  .redeem-voucher-subtitle {
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
