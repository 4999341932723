@import (once) url('../../../../../rootImports.less');

@default-radio-spacer: 0.25rem;

.radio-button {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  & + label {
    background-color: @surface;
    color: @dark-grey;
    font-size: 0.8125rem;
    font-weight: 400;
    width: 100%;
    box-shadow: 0 0 0 1px @grey-faded;
    display: flex;
    flex-direction: row;
    padding: @default-radio-spacer * 4;
    transition: all 100ms ease-in-out;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    cursor: pointer;
    p {
      padding-right: 1rem;
    }
    svg {
      visibility: hidden;
    }
    &:hover {
      box-shadow: 0 0 0 1px @pacific-blue;

      svg {
        visibility: visible;
        color: @grey-light3;
      }
    }
  }
  &:checked + label {
    background-color: @blue-light-faded;
    box-shadow: 0 0 0 1px @pacific-blue;
    -webkit-box-shadow: 0 0 0 1px @pacific-blue;
    svg {
      visibility: visible;
      color: @pacific-blue;
    }
  }
  &:focus {
    & + label {
      transition: all 100ms ease-in-out;
      box-shadow: 0 0 0 1px @pacific-blue, inset 0 0 0 2px #fff;
      -webkit-box-shadow: 0 0 0 1px @pacific-blue, inset 0 0 0 2px #fff;
      outline: 2px solid @pacific-blue;
      outline-offset: -1px;
    }
  }
}
