@google-blue: #4285f4;
@office-brown: #c85a0b;

@button-icon-height: 52px;
@button-icon-border: 2px;

.sso-buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .promo-button.login {
    color: #fff;
    padding-left: 66px;
    box-shadow: none;
    border-radius: 3px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1.5rem;
    min-height: @button-icon-height + (@button-icon-border * 2);

    .icon-wrapper {
      height: @button-icon-height;
      width: 56px;
      left: 2px;
      border-radius: @button-icon-border;
      background-color: #fff;
      background-size: 50%;
      background-size: contain;
    }

    .button-icon {
      width: @button-icon-height;
      height: @button-icon-height;
      background-size: 50% auto;
    }

    &.google {
      background-color: @google-blue;
    }

    &.office {
      background-color: @office-brown;
    }
  }
}
