@import (once) url('../../../../../rootImports.less');

.sidebar-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
}

.sidebar-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 50px);
  align-items: stretch;

  .sidebar-layout__sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    background-color: @white-2;
  }

  .sidebar-layout__main-content {
    position: relative;
  }

  @media only screen and (min-width: @search-sidebar-desktop-min) {
    flex-direction: row;
    width: 100vw;
    max-width: 100%;

    .sidebar-layout__main-content {
      width: 100%;
    }

    .sidebar-layout__sidebar {
      width: 300px;
    }

    &.sidebar-hidden {
      .sidebar-layout__main-content {
        width: calc(100vw);
      }
      .sidebar-layout__sidebar {
        width: 0;
      }
    }
  }
}
