@import (once) url('../_imports.less');

.component-picker {
  [data-component='spinner'] {
    svg {
      .rattle {
        fill: #fff;
      }
      .color1 {
        fill: #d2d2d2;
      }
      .color2 {
        fill: #fff;
      }
      .color3 {
        fill: #b9b9b9;
      }
    }
  }
}

.component-spinner {
  font-family: 'montserrat';
  svg {
    position: absolute;
    top: 0;
    left: 0;
    filter: drop-shadow(0.05em 0.05em 0.1em rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
    .rattle {
      fill: #fff;
    }
    .color1 {
      fill: @yellow;
    }
    .color2 {
      fill: @red;
    }
    .color3 {
      fill: @blue;
    }
  }
}

.thumbPreview .component-spinner svg {
  filter: none;
}

.slide-color-1,
.dragQuestion-color-9 {
  .component-spinner svg .rattle {
    fill: #969696;
  }
}

.full-slide-student-spinner {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 40%;
  z-index: 20;
  .mount {
    z-index: 1;
    position: absolute;
    left: 0;
    // 44 = 100 - (100 / 16 * 9)
    right: 0;
    top: 0;
    bottom: 0;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      z-index: 30;
    }
  }
  .spin-to-win {
    z-index: 2;
    position: absolute;
    top: 47%;
    font-size: 180%;
    height: 2.5em;
    width: 2.5em;
    line-height: 2.5em;
    right: 45%;
    margin: auto;
    background-color: @white;
    border-radius: 100%;
    text-align: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    color: @default-text;
  }
  .winner-banner {
    text-align: center;
    opacity: 0;
    width: 80%;
    margin: auto;
    height: 30%;
    margin-top: 20%;
    z-index: 2;
    position: relative;
    background: @yellow;
    border-radius: 1em;
    border: 0.2em solid @red;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    pointer-events: none;
    .winner-name {
      color: @default-text;
      width: 100%;
      text-align: center;
      font-size: 200%;
      height: 75%;
      display: table;
      .winner-name-inner {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .content-item-button {
      font-size: 70%;
    }
    &.show {
      pointer-events: all;
      animation-name: bounceIn;
      animation-duration: 450ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }
}

#playercontent-inner .winner-banner .text-button {
  font-size: 60%;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
