@import (once) url('../../../../../rootImports.less');
@import (once) url('./LessonPlanRoot.less');

.lesson-plan-link {
  float: left;
  width: auto;
  max-width: @column3;
  .plan-link {
    padding-bottom: 40px; /* make room for blue pencil button */
    .browser {
      display: block;
      z-index: inherit;
      .url {
        font-size: 11px;
      }
      .glass {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .webpage {
    overflow: inherit;
  }
  .plan-button.open-url {
    white-space: nowrap;
    background: @blue;
    color: @white;
    position: absolute;
    left: 50%;
    .translatex(-50%);
    top: 40px;
  }
  .plan-child-edit-buttons {
    position: absolute;
    left: 0;
    top: 200px;
    .button {
      display: inline-block;
      background-color: @blue;
      margin-right: 8px;
    }
  }
}
