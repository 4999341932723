@import (once) url('../../../../../../rootImports.less');

.confirm-modal {
  font-family: @lessonup-body-new;
  color: @default-text;

  &__content-container {
    text-align: center;
  }
}
