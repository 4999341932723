@import (once) url('../../../../../rootImports.less');

.rocket-spinner#spinner {
  position: fixed;
  background: @glasspane;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  &.abs {
    position: absolute;
  }
  #inner-spinner {
    position: absolute;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    left: 50%;
    top: 50%;
    background: url('@{assetsUrl}/img/rocket3.png') no-repeat center center;
    background-size: 100% auto;
    .animation (fly infinite 0.2s ease-in-out);

    &.spinner-small {
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      margin: 5px;
    }
  }

  &.transparent-background,
  &.no-glass {
    pointer-events: none;
    background-color: transparent;
    #inner-spinner {
      background-image: url('@{assetsUrl}/img/rocket4.png');
    }
  }

  &.no-glass {
    background: none;
  }
  &.contained {
    top: 0;
  }
  &.dark {
    background: #666666;
  }
  .spinner-content {
    position: absolute;
    font-weight: 500;
    font-size: 1.2em;
    color: white;
    width: 100%;
    height: 100px;
    left: 0;
    right: 0;
    top: 60%;
  }
}
