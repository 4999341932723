@import (once) url('_imports.less');

table {
  &.th-left {
    th {
      text-align: left;
    }
  }
  th.th-center {
    text-align: center;
  }
  .checkmark-box {
    position: relative;
    .icon-yes {
      left: ~'calc(50% - 10px)';
    }
  }
  span.click {
    cursor: pointer;
    text-decoration: underline;
  }
  td {
    &.maxw-220 {
      max-width: 220px;
    }
    &.maxw-300 {
      max-width: 300px;
      word-wrap: break-word;
    }
    &.minw-210 {
      min-width: 210px;
    }
  }

  .padding-loop(@multiplier) when (@multiplier > 0) {
    &.table-padding-@{multiplier} {
      th:not(.minimize),
      td:not(.minimize) {
        padding: (@spacer * @multiplier);
      }
      th.minimize,
      td.minimize {
        padding-top: (@spacer * @multiplier);
        padding-bottom: (@spacer * @multiplier);
      }
    }

    .padding-loop(@multiplier - 1);
  }

  .padding-loop(4);
}
