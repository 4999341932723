@import (once) url('../_imports.less');

.player-type-link {
  #playercontent-inner {
    /*background: #535a6d;*/
  }
}

#container.presentationMode .weblink-content {
  position: absolute;
  top: 50%;
  width: 100%;
  .transform(translateY(-50%));
}
.weblink-viewer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // for 404 and error msg, otherwise its not visable
  background-color: white;

  .weblink-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.1;
  }

  .browser {
    position: relative;
    z-index: 2;
    width: 60%;
    height: 60%;
    left: 20%;
    top: 20%;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  #why {
    position: absolute;
    z-index: 2;
    font-family: @lessonup-heading;
    text-align: center;
    color: #333;
    font-size: 30%;
    left: 20%;
    top: 84%;
    width: 60%;
  }
}

.browser {
  position: relative;
  z-index: 2;
  width: 256px;
  height: 150px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  .button-tip {
    position: absolute;
    cursor: pointer;
  }

  .browserbar {
    position: relative;
    background: #e2e2e2;
    padding: 1.3% 1.4% 1.3% 15%;
    border-radius: 4px 4px 0 0;
    height: 15%;

    .browser-buttons {
      position: absolute;
      left: 1.7%;
      top: 0;
      width: 11%;
      height: 100%;
      white-space: nowrap;
      font-size: 0;
      .browser-button {
        width: 24%;
        height: 31%;
        border-radius: 1000px;
        display: inline-block;
        margin: 26% 5%;
        &.c1 {
          background: @pink;
        }
        &.c2 {
          background: @yellow;
        }
        &.c3 {
          background: @green;
        }
      }
    }

    .url {
      background: #fff;
      color: @brown-dark;
      text-align: left;
      padding: 1% 3%;
      border-radius: 5px;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      font-size: 37%;
    }
  }
  .webpage {
    position: absolute;
    left: 0;
    top: 15%;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    .glass {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
    }
    .open-button {
      position: absolute;
      left: 50%;
      top: 45%;
      white-space: normal;
      padding: 3% 5%;
      color: #333;
      background-color: @yellow;
      border-radius: 4px;
      font-family: @lessonup-body;
      font-size: 50%;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      white-space: nowrap;
      .transform(translate(-50%, -50%));
      .transform-origin(center, center);
      .transition(all 0.3s ease-out);
    }
  }
  &:hover {
    .webpage {
      .open-button {
        -webkit-transform: translate(-50%, -50%) scale(1.05);
        -moz-transform: translate(-50%, -50%) scale(1.05);
        -ms-transform: translate(-50%, -50%) scale(1.05);
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }
}

/* schooltv weblink has minor adjustments */
.player-type-schooltv {
  #playercontent-inner {
    overflow: hidden;

    .weblink-viewer {
      #brand-logo {
        position: absolute;
        left: 2%;
        top: 2%;
        z-index: 1;
        width: 15%;
        height: 15%;
        background: url('@{assetsUrl}/img/schooltv_logo.png') no-repeat 0 0;
        background-size: contain;
      }
      .weblink-bg {
        background-color: rgb(206, 221, 226);
        -webkit-filter: ~'none';
        -moz-filter: ~'none';
        filter: ~'none';
        opacity: 1;
      }
      .browser {
        .webpage {
          background-color: #fff;
          .open-button {
            background-color: #00a8e4; /* schooltv blue */
            color: #fff;
            &:after {
              height: 40px;
              right: 20px;
              background-image: url('@{assetsUrl}/img/play.png');
            }
          }
          .glass {
            display: none;
          }
        }
      }
      #why {
        color: #fff;
      }
    }
  }
}

.player-type-npo {
  #playercontent-inner {
    overflow: hidden;

    .weblink-viewer {
      #brand-logo {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 1;
        width: 60px;
        height: 60px;
        background: url('@{assetsUrl}/img/npo_logo.png') no-repeat 0 0;
        background-size: auto 100%;
      }
      .weblink-bg {
        background: #272727; /* npo grijs */
        -webkit-filter: ~'none';
        -moz-filter: ~'none';
        filter: ~'none';
        opacity: 1;
      }
      .browser {
        .webpage {
          .open-button {
            background-color: #ff6d00; /* npo oranje */
            color: #fff;
            &:after {
              height: 40px;
              right: 20px;
              background-image: url('@{assetsUrl}/img/play.png');
            }
          }
          .glass {
            display: none;
          }
        }
      }
      #why {
        color: #fff;
      }
    }
  }
}
