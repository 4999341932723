@import (once) url('../../../../../../rootImports.less');

.video-thumb.playerWrapper {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  iframe,
  video {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
  }

  .icon {
    width: 100%;
    height: 1.5em;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .video-pin-indicator {
    transform: translateX(-15%);
    transition: bottom 0.3s ease-out;
    svg {
      height: 100%;
    }
    .text {
      font-size: 20px;
      fill: @default-text;
    }
    .text-small {
      font-size: 6px;
      fill: @default-text;
    }
    .center {
      fill: @yellow;
    }
    .core {
      pointer-events: all;
      cursor: pointer;
      &:hover {
        .center {
          fill: @blue;
        }
        .text,
        .text-small {
          fill: white;
        }
      }
    }
    .thumb-wrapper {
      position: absolute;
      right: 80%;
      bottom: 80%;
      padding: 5px;
      background-color: @yellow;
      border-radius: 5px;
      display: none;
      &.show {
        display: block;
      }
    }
  }
}
.thumbPreview svg.video-pin-indicator {
  height: 4em;
}
