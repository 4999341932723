@import (once) url('../../../../../rootImports.less');

@pin-result-margin: 5px;
@topbarHeight: 60px;

.pin-search-page {
  padding-bottom: @topbarHeight + 5px;
  .sidebar-layout {
    margin-top: @topbarHeight;
  }
}
.pinsearch-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
}

.pin-result {
  position: relative;
  overflow: hidden;
  border: 5px solid transparent;
  padding: 3px;

  &.highlighted {
    border: 5px solid @blue;
  }
}

.pin-result-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;

  background-color: white;
  border: 2px solid @brown-dark;
  border-radius: 3px;
  height: 25px;
  width: 25px;
}

.pin-search-bar {
  height: @topbarHeight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  background-color: @white-2;
  position: fixed;
  width: 100%;
  z-index: 22;
  left: 0;

  .action-button.align-right {
    position: absolute;
    right: 1em;
  }

  &.top-bar {
    top: 0;
  }

  &.bottom-bar {
    bottom: 0.5em;
  }
}

.image-result {
  margin: 10px;
  .image {
    width: 100%;
    height: 100%;
    background: @white-2 no-repeat center center;
    background-size: contain;
  }
  .actions {
    padding: 10px;
    opacity: 0;
    display: flex;
    justify-content: space-around;
  }
  &:hover .actions {
    opacity: 1;
  }
}
