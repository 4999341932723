@import (once) url('../../../../../rootImports.less');

.checkbox {
  display: inline-flex;
  position: relative;

  .checkbox-themes;
  .height;
  cursor: pointer;

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
    .checkbox__control {
      background: inherit;
    }
  }
}

.checkbox__control {
  display: inline-block;
  position: relative;
  border-radius: 4px;
  padding: 8px;
  border: 2px solid @border;
}

@hitBoxSizeEnd: 90%;
.checkbox__hit-box {
  position: absolute;
  left: -@hitBoxSizeEnd;
  right: -@hitBoxSizeEnd;
  top: -@hitBoxSizeEnd;
  bottom: -@hitBoxSizeEnd;
  border-radius: 100%;
  cursor: inherit;
}

.checkbox--hit-box {
  .checkbox__hidden-input {
    pointer-events: none;
  }
  svg {
    pointer-events: none;
  }
}

.checkbox__hit-box-animation {
  pointer-events: none;
  position: absolute;
  border-radius: 100%;
}

@hitBoxAnimSizeStart: 50%;
@hitBoxAnimSizeEnd: 0%;
.checkbox__hit-box:hover .checkbox__hit-box-animation {
  left: @hitBoxAnimSizeEnd;
  right: @hitBoxAnimSizeEnd;
  top: @hitBoxAnimSizeEnd;
  bottom: @hitBoxAnimSizeEnd;
}

.checkbox__control svg {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.checkbox--checked .checkbox__control {
  background: @cyan;
  border-color: darken(@cyan, 5%);
  svg {
    opacity: 1;
  }
}

.checkbox__label {
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-family: @lessonup-body-new;
}

.checkbox__hidden-input {
  position: absolute;
  display: block;
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: inherit;
}

.checkbox-themes() {
  .checkbox__control {
    background: white;
    border: 2px solid @border;

    .checkmark path {
      fill: #fff;
    }
  }

  &.checkbox--checked .checkbox__control {
    background: @cyan;
    border-color: darken(@cyan, 5%);
  }

  &.theme-secondary {
    .checkbox__control {
      border-color: @brown-dark;

      .checkmark path {
        fill: @grey-bg;
      }
    }

    &.checkbox--checked .checkbox__control {
      background: @yellow;

      .checkmark path {
        fill: @brown-dark;
      }
    }
  }
}

.height() {
  .checkbox__control {
    width: 19px;
    height: 19px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &.height-large .checkbox__control {
    width: 26px;
    height: 26px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.height-small {
    .checkbox__control {
      width: 16px;
      height: 16px;
      border-width: 1px;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    .checkbox__label {
      font-size: 80%;
    }
  }
}
