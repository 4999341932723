@import (once) url('../../../../rootImports.less');

.disabled() {
  cursor: normal;
  pointer-events: none;
  opacity: 0.4;
}

.child-picker-page {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;

  &__title {
    font-family: @lessonup-title;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: @spacer * 2;
    text-align: center;
  }

  &__description {
    p {
      margin: 0;
    }

    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: @spacer * 8;
  }

  &__cards-container {
    width: 100%;
    max-width: @mq-sm-min;
    margin-bottom: @spacer * 8;
    max-height: 360px; // Cuts one card in half so its clear the user can scroll
    overflow-y: scroll;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: @spacer * 4;
  }

  &__checkboxes {
    width: 100%;
    max-width: @mq-sm-min;
  }

  &__create-account-button {
    margin-bottom: @spacer * 2;
  }

  &__skip-button {
    margin-bottom: @spacer * 4;
    text-align: center;

    &--disabled {
      .disabled;
    }
  }
}
