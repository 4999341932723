@import (once) url('../../../../../rootImports.less');

@max-checklist-items: 5; //Current largest number of checklist items.
@max-checklist-container-height: (@max-checklist-items * 2 * 1.4) + @max-checklist-items * 0.6rem;

.center-text {
  text-align: center;
}

.select-application-wrapper {
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  .registration-select-application {
    display: flex;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .check-list-container {
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
      text-align: start;

      // ie11 fallback for line-clamp, does not include ellipsis
      height: @max-checklist-container-height;
      overflow: hidden;
    }

    .check-list {
      padding-inline-start: 0;
    }

    @media (min-width: @mq-sm-min) {
      flex-direction: row;

      .spread-content {
        display: flex;
        justify-content: space-between;
      }

      img.registration-image {
        height: 40%;
        bottom: 0;
      }
    }

    @media (min-width: @mq-md-min) {
      img.registration-image {
        height: 60%;
        bottom: -30%;
      }
    }

    &.stack {
      width: 100%;

      @media (min-width: @mq-sm-min) {
        margin-left: -20px;

        > * {
          margin-left: 20px;
        }
      }
    }
  }
}
