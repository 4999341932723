@import (once) url('../../../../rootImports.less');

.channel-overview {
  height: 100%;

  .channel-overview-inner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    margin-top: -10%;
    padding: 50px;

    background: transparent;
    left: 20px;
    right: 20px;

    .page-info {
      background-color: #fff;
      padding: 25px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .section-visual {
    min-width: 900px;
    left: 0;
    top: 0;
    right: 0;
    height: 200px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-image: url(/search/public/img/main-bg-blue.jpg);
  }
  .section-layer-over-visual {
    min-width: 900px;
    left: 0;
    top: 0;
    right: 0;
    height: 200px;
    background: rgba(0, 0, 0, 0.5);
  }

  .channel-wrapper {
    display: flex;
    flex-direction: row;
  }
}

section[data-page='channels'] {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .explorer-top-bar {
    border-bottom: none;
    background: transparent;
    .topbar-buttons li.active a {
      position: relative;
      background: transparent;
      color: #fff;
      &:after {
        content: '';
        position: absolute;
        background: #fff;
        left: 20px;
        right: 20px;
        bottom: 10px;
        height: 2px;
      }
    }
  }
  #section-visual {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 500px;
    background: no-repeat center center;
    background-size: cover;
    background-image: url(/search/public/img/main-bg-blue.jpg);
  }
  #section-layer-over-visual {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 500px;
    background: rgba(0, 0, 0, 0.6);
    &.gradient-blue {
      background: linear-gradient(@blue, #fff);
    }
  }
  #page {
    background: transparent;
    left: 20px;
    right: 20px;
    h2 {
      text-align: center;
      .font-odin;
      font-weight: 400;
      font-size: 35px;
      text-transform: uppercase;
    }
    #page-info {
      text-align: center;
      min-height: 300px;
      padding-top: 60px;
      margin: auto;
      color: #fff;

      h1 {
        position: relative;
        display: inline-block;
        .font-odin;
        font-size: 3em;
        font-weight: normal;
        text-transform: uppercase;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        margin: 0 0 20px 0;
        @media (min-width: 767px) {
          font-size: 4em;
        }

        &:after {
          content: '';
          position: absolute;
          left: 15%;
          right: 15%;
          bottom: -10px;
          height: 5px;
          background: #fff;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
          border-radius: 5px;
        }
      }
      p {
        position: relative;
        .font-montserrat;
        color: #fff;
        line-height: 1.5em;
        font-size: 18px;
        text-align: justify;
      }
    }

    #section-content {
      background: #fff;
      border-radius: 4px 4px 0 0;
      // min-height: 800px;
      padding: 20px 0 0 0;
      position: relative;

      @media (min-width: @mq-sm-min) {
        padding: 40px;
        margin-left: 40px;
        margin-right: 40px;
      }
      & > h2 {
        color: @cyan;
        margin: 0 0 20px 0;
        text-align: center;
      }
      hr {
        position: relative;
        background: transparent;
        border: none;
        border-bottom: solid @cyan 3px;
        margin: 0 auto 40px auto;
        max-width: 1400px;
        opacity: 0.5;
      }
    }
  }
}

.channels-wrapper {
  max-width: 1420px;
  width: 100%;
  margin-bottom: 40px;
  margin: auto;
  &.featured {
    height: 220px;
  }
  .flex-item {
    padding: 2px;
    width: 32%;
    float: left;
    margin: 5px 0.65%;
    &.dummy {
      height: 1px;
      margin: 0 10px;
    }
  }
}

.channel-card {
  position: relative;
  display: block;
  text-decoration: none;
  height: 200px;
  color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:after {
    position: absolute;
    content: '';
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    border: solid @blue 2px;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }

  .visual {
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: cover;
  }
  .glass {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .channel-icon {
    width: 70px;
    height: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50px;
    left: 20px;
  }
  .name {
    .font-odin;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 20px;
  }
  .subtitle {
    font-size: 14px;
  }
  .channel-info-text {
    .font-montserrat;
    position: relative;
    margin-left: 100px;
    padding: 50px 20px 0 0;
  }
}

@media (min-width: 850px) {
  .channels-wrapper.featured {
    max-width: 1000px;
    height: 220px;
    .flex-item {
      width: 48%;
    }
  }
}

@media (max-width: 1350px) {
  .channels-wrapper {
    max-width: 1000px;
    height: 220px;
    .flex-item {
      width: 48%;
    }
  }
}

@media (max-width: 850px) {
  .channels-wrapper.featured {
    height: 400px;
  }
  .channels-wrapper {
    max-width: 500px;
    .flex-item {
      width: 100%;
    }
  }
}

section[data-page='channels'] #page #page-info {
  max-width: 740px;
  padding: 60px 20px 0 20px;
}

.channel-interest-message {
  max-width: 500px;
  margin: auto;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}
