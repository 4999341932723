@import (once) url('../_imports.less');

[data-component='text'] {
  line-height: 0;
  .outline {
    pointer-events: none;
  }
  .text-comp-inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .text-value {
    width: 100%;
    line-height: 1.5em;
    border: none !important;
    outline: none;
    word-wrap: break-word;

    * {
      white-space: pre-line;
    }

    &.vertical-align-center {
      position: absolute;
      top: 50%;
      .translatey(-50%);
      padding-top: 0;
      padding-bottom: 0;
    }
    &.vertical-align-bottom {
      position: absolute;
      bottom: 0;
    }
  }
}
.edit-mode {
  .text-padding-0 {
    &.text-align-left .text-value {
      /* use 2px padding to make room for the cursor (otherwise it's invisible behind the outline)
			but only if the comp has no padding of its own */
      padding-left: 2px !important;
    }
    &.text-align-right .text-value {
      /* use 2px padding to make room for the cursor (otherwise it's invisible behind the outline)
			but only if the comp has no padding of its own */
      padding-right: 2px !important;
    }
  }
}

.text-padding-1 {
  padding: 0.2em;
}
.text-padding-2 {
  padding: 0.35em;
}
.text-padding-3 {
  padding: 0.6em;
}
.text-padding-4 {
  padding: 1em;
}
.text-padding-5 {
  padding: 1.5em;
}

.border-width-0 {
  border: 0;
}
.border-width-1 {
  border: 0.02em solid gray;
}
.border-width-2 {
  border: 0.05em solid gray;
}
.border-width-3 {
  border: 0.1em solid gray;
}
.border-width-4 {
  border: 0.15em solid gray;
}
.border-width-5 {
  border: 0.2em solid gray;
}
.border-width-6 {
  border: 0.3em solid gray;
}
