@import (once) url('../../../../../../rootImports.less');

@card-icon-size: 5rem;

.card-icon {
  width: @card-icon-size;
  height: @card-icon-size;
  border-radius: 4px;
  background-color: @white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &__custom-background {
    width: 100%;
    height: 100%;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &--placeholder {
    background-color: @outline;
    color: @hurricane;
  }
}
