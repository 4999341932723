@import (once) url('_imports.less');

.checkbox-group {
  position: relative;
  text-align: left;
  min-height: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;

  .label {
    color: #574c4a;
  }

  .checkbox {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    border: 2px solid #574c4a;
    border-radius: 6px;
    .transition(background-color 0.2s ease-out);

    .check {
      position: absolute;
      background-image: url(img/icons/white/checkmark.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 75% auto;
      height: 100%;
      width: 100%;
      display: none;
    }

    &.checked {
      background-color: @cyan;

      .check {
        display: block;
      }
    }
  }

  .disabled {
    &.checkbox {
      opacity: 0.7;
      background-color: @grey-light2;
    }

    &.label {
      color: @grey-light2;
    }
  }

  &.inline {
    margin: 0 7px;
    display: inline-block;

    .label {
      position: relative;
      top: -6px;
      left: 2px;
    }
  }

  &.check-right {
    .checkbox {
      float: right;
    }

    .label {
      left: inherit;
      right: 50px;
    }
  }

  &.center {
    width: 30px;
    margin: auto;
  }

  .button-tip {
    margin-top: 15px;
    left: -7px;
  }
}

#playercontent-inner {
  .checkbox-group {
    min-height: 2em;

    .checkbox {
      height: 1.5em;
      width: 1.5em;
      border-radius: 0.3em;
    }

    .label {
      height: 1.5em;
      top: 0.1em;
      left: 2em;
    }
  }
}

div[data-schooltype].checkbox-group {
  margin-bottom: 0;
}
