@import (once) url('_imports.less');

#toast {
  position: fixed;
  top: 5px;
  left: 5vw;
  right: 5vw;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  padding: 15px 50px 15px 20px;
  border-radius: 20px;
  transform: translateY(-120%);
  max-width: 700px;
  z-index: 10003;
  .transition(all 0.3s ease-out);

  //TODO fix animation so it comes from the bottom
  &.show {
    transform: translateY(0%);
    &.position-bottom {
      top: auto;
      bottom: 50px;
    }
    @media (min-width: 768px) {
      transform: translate(-50%, 0%);
    }
  }

  @media (max-width: 320px) {
    font-size: 14px;
  }

  @media (min-width: 768px) {
    left: 50%;
    right: auto;
    min-width: 100px;
    padding: 8px 50px 6px 20px;
    transform: translate(-50%, -120%);
  }

  &.focus {
    background-color: @pink;
    color: #fff;
  }
  &.green {
    background-color: lighten(@green, 5);
    color: #fff;
  }
  &.pink {
    background-color: @pink;
    color: #fff;
  }
  [data-role='close-toast'] {
    position: absolute;
    width: 30px;
    height: 30px;
    min-height: 30px; /* override default button style */
    background-size: 50% auto;
    background-repeat: no-repeat;
    background-position: center center;
    right: 10px;
    top: 50%;
    .translatey(-50%);
  }
  .actions {
    margin-top: 5px;
    white-space: nowrap;
    .content-item-button + .content-item-button {
      margin-left: 8px;
    }
  }
}
