@import (once) url('../../../../../rootImports.less');

.hover-input-styling() {
  border: 1px solid @dark-grey;
}

.active-input-styling() {
  border: 1px solid @pacific-blue;
}

.focus-input-styling() {
  box-shadow: 0 0 0 2px @pacific-blue;
  border: 1px solid transparent;
}

.disabled-input-styling() {
  &::placeholder {
    color: @grey-light2;
  }
}

.placeholder-input-styling() {
  &::placeholder,
  &::-webkit-input-placeholder {
    opacity: 1;
    color: @grey-light3;
    font-size: 1rem;
  }
}

.input-states() {
  &:enabled,
  input:enabled {
    color: @dark-grey;

    &::placeholder,
    &::-webkit-input-placeholder {
      color: @dark-grey;
      opacity: 1;
      color: @grey-light3;
      font-size: 1rem;
    }
  }
}

.input-error-styling() {
  border-color: @red-new !important;
}
