@import (once) url('../_imports.less');

/* some colors and layouts may overlap with slide, but some don't - so don't merge! */

.question-color-1 {
  .content-background {
    background-color: @blue-dark;
  }
  color: #fff;
}
.question-color-2 {
  .content-background {
    background-color: @yellow;
  }
  color: #000;
}
.question-color-3 {
  .content-background {
    background-color: @pink;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.question-color-4 {
  .content-background {
    background-color: @cyan;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.question-color-5 {
  .content-background {
    background-color: #000;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.question-color-6 {
  .content-background {
    background-color: @blue;
  }
  color: darken(@blue, 45);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.question-color-7 {
  .content-background {
    background-color: @orange;
  }
  color: darken(@orange, 40);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.question-color-8 {
  .content-background {
    background-color: @correct-light;
  }
  color: darken(@correct, 25);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.question-color-9 {
  .content-background {
    background-color: white;
  }
  color: #111;
  &.edit-mode .text {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
#question {
  &.question-layout-1 {
    .question {
      top: 5%;
      left: 5%;
      width: 90%;
      height: 35%;
    }
    .image {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
    }
  }

  &.question-layout-2 {
    .question {
      left: 0;
      top: 0;
      width: 50%;
      height: 40%;
    }
    .image {
      left: 50%;
      top: 0;
      width: 50%;
      bottom: 60%;
      background-size: contain;
    }
  }

  &.question-layout-3 {
    .question {
      left: 50%;
      top: 0;
      width: 50%;
      height: 40%;
    }
    .image {
      left: 0%;
      top: 0;
      width: 50%;
      bottom: 60%;
      background-size: contain;
    }
  }

  &.question-layout-4 {
    .question {
      display: none;
    }
    .image {
      left: 0;
      top: 0;
      width: 100%;
      bottom: 60%;
      background-size: contain;
    }
  }
  &.question-layout-5 {
    .question {
      display: none;
    }
    .image {
      display: none;
    }
  }
}
