@import (once) url('../../../../../rootImports.less');
@import (once) url('./LessonPlanRoot.less');

.plan-child {
  position: relative;
  margin-right: @plan-child-spacing;
  margin-bottom: @plan-child-spacing;
  float: left;
  .child-buttons {
    z-index: 200;
    line-height: 0;
    position: absolute;
    background: @blue;
    padding: 0 3px;
    right: -10px;
    top: -40px;
    border-radius: 5px 5px 0 0;
    opacity: 0;
    pointer-events: none;
    .translatey(10px);
    .transition(all 0.3s ease-out);
    .child-button {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 15px auto;
    }
  }
  &:after {
    position: absolute;
    content: '';
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    border: solid @blue 2px;
    border-radius: 5px 0 5px 5px;
    pointer-events: none;
    opacity: 0;
  }
  &.selected {
    .child-buttons {
      .translatey(0);
      opacity: 1;
      pointer-events: all;
    }
    &:after {
      opacity: 1 !important;
    }
  }

  .small-buttons {
    line-height: 0;
    background: @blue;
    padding: 0 0 0 3px;
    left: inherit;
    top: -40px;
    right: 190px;
    border-radius: 5px 0 0 0;
    opacity: 0;
    position: absolute;
    display: block !important;
    pointer-events: none;
    z-index: 201;
    .translatey(10px);
    .transition(opacity 0.3s ease-out) !important;
    .transition(transform 0.3s ease-out) !important;
    &:before {
      content: '';
      right: -4px;
      top: 0;
      width: 8px;
      height: 4px;
      position: absolute;
      background: @blue;
      pointer-events: none;
    }
    .button {
      margin: 0;
      line-height: 0;
      border: none;
      display: inline-block;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 15px !important;
      background-color: transparent;
    }
  }

  &.one-column {
    .base-summary-card {
      float: left;
      margin: 0 @plan-child-spacing @plan-child-spacing 0;
      width: @column;
    }
  }

  &.one-column {
    width: @column;
    float: left;
    .paragraph,
    .content-page-paragraph,
    .plan-image,
    .plan-link,
    .plan-video {
      width: @column;
    }
  }

  &.three-columns {
    width: @column;
    .paragraph,
    .content-page-paragraph,
    .plan-image,
    .plan-link,
    .plan-video {
      width: @column;
    }
    .plan-button.open-url {
      left: 325px;
    }
  }

  &.two-columns {
    .paragraph,
    .content-page-paragraph,
    .plan-image,
    .plan-link,
    .plan-plan,
    .plan-video {
      float: left;
      width: @column;
    }
  }

  @media (min-width: calc((@column2) + (@plan-child-spacing * 2))) {
    &.two-columns {
      .paragraph,
      .content-page-paragraph,
      .plan-image,
      .plan-link,
      .plan-lesson,
      .plan-video {
        width: @column2;
      }
      .plan-button.open-url {
        left: 325px;
      }
    }
    &.three-columns {
      width: @column2;
      .paragraph,
      .content-page-paragraph,
      .plan-image,
      .plan-link,
      .plan-video {
        width: @column2;
      }
      .plan-button.open-url {
        left: 325px;
      }
    }
  }
  @media (min-width: calc(@column3 + @plan-child-spacing)) {
    &.three-columns {
      width: @column3;
      .paragraph,
      .content-page-paragraph,
      .plan-image,
      .plan-link,
      .plan-video {
        width: @column3;
      }
    }
  }
}
