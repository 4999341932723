@import (once) url('_imports.less');

.drag-question-object-outline,
.dropzone-object-outline {
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  border: solid @blue-dark 4px;
  z-index: 19;
  pointer-events: none;
  border-radius: 8px;

  &.round-outline {
    border-radius: 100%;
  }
}
.drag-question-object-grippy,
.dropzone-object-grippy {
  position: absolute;
  left: 50%;
  top: 0;
  .translate(-50%, -50%);
  width: 34px;
  height: 34px;
  margin-top: -2px;
  background: @blue url('@{assetsUrl}/img/icons/white/move2.png') no-repeat center center;
  background-size: auto 70%;
  border: solid @blue-dark 4px;
  z-index: 21;
  border-radius: 100px 100px;
  cursor: move;
  &.drag-correct-true {
    background-color: @green;
  }
  &.drag-correct-false {
    background-color: @red;
  }
}

.sub-buttons-wrapper {
  top: 0;
  bottom: 0;
  position: absolute;
}

.dragQuestion-color-1 {
  .drag-question-object-grippy,
  .drag-question-object-outline {
    border-color: lighten(@blue-dark, 10);
  }
}

/* dropzones have a red color theme and other icon */
.dropzone-object-outline {
  border-color: @red;
}
.dropzone-object-grippy {
  border-color: @red;
  background-color: @pink;
  background-image: url('@{assetsUrl}/img/icons/white/target.png');
  cursor: default;
}

/* thumb view outlines and grippys are fixed size smaller */
.thumbPreview .drag-question-object-outline,
.thumbPreview .dropzone-object-outline {
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-width: 1px;
  border-radius: 2px;
}
.thumbPreview .drag-question-object-grippy,
.thumbPreview .dropzone-object-grippy {
  width: 14px;
  height: 14px;
  background-size: 90% auto;
  border-width: 2px;
  cursor: default;
}
.is-touch .grippy-min-hitbox {
  width: 60px;
  height: 60px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

[data-component='audio'] .drag-question-object-outline,
[data-component='hotspot'] .drag-question-object-outline {
  border-radius: 10000px !important;
}

@media screen and (max-width: 800px), screen and (max-height: 500px) {
  .drag-question-object-grippy,
  .dropzone-object-grippy {
    width: 23px;
    height: 23px;
    background-size: 80% auto;
    border-width: 3px;
  }
  [data-drag-question-source='true'].component-text {
    .text-value {
      margin-top: 2% !important;
    }
  }
}

@media screen and (max-width: 550px), screen and (max-height: 350px) {
  .playercontent {
    .drag-question-object-grippy,
    .dropzone-object-grippy {
      width: 20px;
      height: 20px;
      background-size: 80% auto;
      border-width: 3px;
    }
    .drag-question-object-outline,
    .dropzone-object-outline {
      left: -2px;
      top: -2px;
      right: -2px;
      bottom: -2px;
      border-width: 2px;
      border-radius: 3px;
    }
  }
}

@media screen and (max-width: 400px), screen and (max-height: 290px) {
  .playercontent {
    .drag-question-object-outline,
    .dropzone-object-outline {
      left: -1px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      border-width: 1px;
      border-radius: 2px;
    }
    .drag-question-object-grippy,
    .dropzone-object-grippy {
      width: 14px;
      height: 14px;
      background-size: 90% auto;
      border-width: 2px;
    }
  }
}

@media screen and (max-width: 350px), screen and (max-height: 250px) {
  .playercontent {
    .drag-question-object-grippy,
    .dropzone-object-grippy {
      width: 10px;
      height: 10px;
      background-size: 95% auto;
      border-width: 1px;
    }
  }
}
