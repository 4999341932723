@import (once) url('../../../../../../../rootImports.less');

.table .row {
  &.disabled {
    filter: opacity(0.6);
    cursor: default;
    background: rgba(0, 0, 0, 0);
  }

  &--highlighted {
    background: @wild-sand;
    cursor: pointer;
  }
}
