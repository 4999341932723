@import (once) url('../../../../../../../rootImports.less');

@emoji-width: 1em;
@emoji-margin: 0.1em;
@emoji-size-multiplier: 2;
@emoji-count: 5;
@outer-margin: @emoji-margin * 2 * @emoji-size-multiplier;

.emoji-wrapper {
  display: flex;
  justify-content: center;
  width: ~'calc((@{emoji-width} * @{emoji-count} * @{emoji-size-multiplier}) + (@{emoji-margin} * @{emoji-count} ) + @{outer-margin})';
  max-width: 100%;
  margin: auto;
  height: @emoji-width * @emoji-size-multiplier;

  .emoji {
    cursor: pointer;
    // Commented out until we find a lighter solution. https://lessonup.atlassian.net/browse/LI-374
    // font-family: @lessonup-emoji;
    font-size: 100% * @emoji-size-multiplier;
    width: @emoji-width;
    margin-right: @emoji-margin;
    margin-left: @emoji-margin;
    opacity: 0.6;
    max-width: 20%;

    &:hover {
      opacity: 1;
    }
  }
  .active {
    opacity: 1;
    cursor: auto;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .emoji {
      margin-right: 0.6em;
      margin-left: 0.6em;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
