@import (once) url('../../../../../rootImports.less');

@table-border-color: @grey-light3;
@table-inner-border-width: 1px;
@table-outer-border-width: 2 * @table-inner-border-width;

.styled-table {
  border: solid @table-outer-border-width @table-border-color;

  &--wrap-content {
    .header-cell,
    .cell {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  &--zebra {
    background: @white;

    :not(thead) {
      tr:nth-child(odd) {
        background: @wild-sand;
      }
    }
  }

  tr {
    border: solid @table-inner-border-width @table-border-color;
  }

  .table-header {
    border-bottom: solid @table-outer-border-width @table-border-color;
  }

  .header-cell {
    color: @brown-dark;
    font-family: @lessonup-body-new;
    font-size: @table-font-size;
    text-transform: uppercase;
  }

  .cell {
    font-size: @table-font-size;
    color: @grey-mid-3;
  }
}
