.check-list-item {
  margin: 0.6rem 0 0.6rem 1rem;
  list-style: none;
  display: flex;
  align-items: center;

  .list-icon {
    height: 25px;
    width: 25px;
    background-size: 100%;
    display: inline-flex;
    background-repeat: no-repeat;
    flex-shrink: 0;

    &.icon-small {
      height: 16px;
      width: 16px;
    }
  }

  span {
    margin-left: 0.6rem;
  }
}
