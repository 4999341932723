@import (once) url('../../../../../rootImports.less');

@timing: 0.4s;

.button-loader {
  display: inline-flex;
  position: absolute;
  width: 64px;
  height: 20px;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}
.button-loader div {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.button-loader div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 @timing infinite;
}
.button-loader div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 @timing infinite;
}
.button-loader div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 @timing infinite;
}
.button-loader div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 @timing infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
