@import (once) url('../../../../../rootImports.less');

@cardWidth: 256px;
@marginSmall: 20px;
@marginLarge: 40px;

#channelSeries,
.content-page {
  position: relative;
  min-height: 200px;
  padding: 20px;
  overflow: visible;
  margin-bottom: 40px;
  @media (min-width: @mq-sm-min) {
    padding: 40px;
  }
  .subfolder-title {
    font-family: @lessonup-heading;
  }
  .description {
    white-space: pre-wrap;
  }
  .folder-container {
    margin-top: 15px;
  }
  .folder-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;

    > * {
      margin: 20px;
    }

    .base-summary-card {
      width: @cardWidth;
      min-width: @cardWidth;
      @media (min-width: @mq-sm-min) {
        flex-grow: 0;
      }
    }

    .lesson-summary-card {
      outline: 2px solid #f3f3f3;

      .thumb-banner {
        top: 105px;
      }
    }
  }
}

.folder-wrapper.no-series-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -20px;
  text-decoration: none;

  .lesson-folder {
    height: 270px;
    text-decoration: none;
    width: 100%;
  }

  & > *:not(.clearfix) {
    margin: 20px;
    margin-top: 0;
    flex-grow: 1;
    width: 256px;
    max-width: 300px;

    @media (min-width: @mq-sm-min) {
      flex-grow: 0;
    }
  }
}
