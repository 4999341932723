@import (once) url('_imports.less');

#common-popup-box {
  .user-select (text);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 10px;
  overscroll-behavior: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.corner-button-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  margin: auto;
}
.corner-close-button {
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: #000;
  border-radius: 25px;
  right: 5px;
  top: 5px;
  z-index: 2001;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url('@{assetsUrl}/img/icons/white/close-2.png') no-repeat center center;
    background-size: 50% auto;
  }
}

/* mac safari fix that causes pages to slow down immensely when reviewing grades */
html:not(.mac.safari) .common-popup-box,
.common-popup-style,
#page-inner .common-popup-style {
  #common-popup-inner {
    &.popup-fullscreen {
      .popup-content {
        max-height: inherit !important;
      }
    }
  }
}

.common-popup-box,
.common-popup-style,
#page-inner .common-popup-style {
  #common-popup-glasspane {
    position: absolute;
    background: @glasspane;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  @popup-border-width: 4px;

  #common-popup-inner {
    z-index: 2;
    position: relative;
    background: @grey;
    overflow: hidden;
    &.small {
      min-height: 280px;
    }

    &.popup-fullscreen {
      position: absolute;
      left: 20px;
      top: 20px;
      right: 20px;
      bottom: 20px;
      margin: auto;
      width: inherit;
      .transform(none);
      .popup-content {
        overflow-y: auto;
        height: 100%;
        width: 100%;
        max-height: initial;
        max-width: initial;
        .can-scroll;
      }
    }
    &.fullscreen-nested {
      position: absolute;
      top: -@popup-border-width;
      left: -@popup-border-width;
      bottom: -@popup-border-width;
      right: -@popup-border-width;
      .popup-content {
        width: 100%;
      }
    }
    &.white {
      background: @white;
    }
    &.thick-bar {
      .bar {
        height: 60px;
        font-size: 23px;
        line-height: 66px;
      }
      .button.top-right {
        top: 14px;
      }
    }
    .default-ok {
      margin-top: 10px;
      min-width: 100px;
      text-align: center;
      margin-bottom: 7px;
    }
    /* box over box, force absolute to make visible */
    #common-popup-box {
      position: absolute;
    }
  }

  .popup-content {
    /*position: relative;*/ /* this throws stuff off in editGroupPopup and such */
    overflow-x: hidden;
    overflow-y: auto;
    max-height: ~'calc(90vh - 100px)';
    max-width: 90vw;
    width: 550px;
    color: @default-text;
    font-family: @lessonup-body-new;
    padding: 20px 20px 0 20px;
    .can-scroll;
    ul,
    ol {
      line-height: 1.4em;
    }
    // IE en FF fuck things up otherwise
    & > .hc:last-child,
    & > .content-item-button:last-child {
      margin-bottom: 20px;
    }
    p:first-child {
      margin-top: 0;
    }
  }
  .bar {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 40px 0 20px;
    background: @yellow;
    color: @default-text;
    font-size: 24px;
    line-height: 66px;
    font-weight: normal;
    font-family: @lessonup-logo;
    overflow-y: hidden;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    .popup-tab {
      position: relative;
      min-width: 130px;
      float: left;
      background-color: darken(@yellow, 15);
      color: white;
      height: 35px;
      margin-top: 25px;
      text-align: center;
      font-family: @lessonup-body-new;
      font-size: 15px;
      margin-right: 10px;
      border-radius: 5px 5px 0 0;
      cursor: pointer;
      line-height: 35px;
      padding: 0 15px;
      white-space: nowrap;
      &.no-min-width {
        min-width: auto !important;
      }
      &.tab-right {
        float: right;
      }
      &.discourage {
        background-color: darken(@yellow, 25);
        color: darken(white, 10);
      }
      &.disabled {
        background-color: #fff;
        pointer-events: none;
        color: @default-text;
        opacity: 0.2;
      }
      &.active {
        background-color: white;
        color: @black;
      }
      .red-badge {
        position: absolute;
        width: 16px;
        height: 16px;
        background: red;
        border-radius: 20px;
        right: -7px;
        top: -7px;
      }
      &.tab-right {
        float: right;
      }
    }
    .instructions-button {
      position: absolute;
      top: 15px;
      right: 31px;
      font-size: 93%;
      .button-tip {
        top: 35px;
      }
    }

    .popup-title-icon {
      display: inline;
      margin-right: 5px;
      margin-top: auto;
    }
  }
  .button.top-right {
    width: 30px;
    height: 30px;
    background-size: auto 60%;
    position: absolute;
    right: 10px;
    z-index: 99;
    top: 15px;
    background-color: transparent;
  }

  .fixed-bottom-bar {
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
  .no-content-styling-simple {
    background: @grey;
  }
  /* wrap popup content inside content-styling class to allow NON-styling with commonPopup @noContentStyling boolean */
  .content-styling {
    h3 {
      color: @default-text;
      font-family: @lessonup-body-new;
      font-size: 16px;
      line-height: 1.2em;
      margin: 0 0 10px 0;
    }
    h3 + p {
      margin-top: 2px;
    }
    p,
    ul,
    ol,
    li {
      font-size: 16px;
      line-height: 1.2em;
      color: @default-text;
    }
    h4 {
      .font-odin;
      line-height: 1.2em;
      margin: 0 0 3px 0;
      color: @cyan;
    }
    label.reg {
      display: inline-block;
      color: @default-text;
    }
    label.default {
      display: inline-block !important;
      color: @default-text !important;
      text-transform: none !important;
      text-align: left !important;
      min-width: auto !important;
    }
    hr {
      height: 1px;
      border: none;
      background: #ccc;
    }
    select {
      font-size: 16px;
      padding: 5px 10px;
      max-width: 296px;
      margin-top: 5px;
    }
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    input[type='date'],
    .form input[type='text'],
    .form input[type='number'],
    .form input[type='email'],
    .form input[type='password'],
    .form input[type='date'] {
      font-size: inherit;
      padding: 5px 10px;
      border: solid darken(@grey, 10) 1px;
      border-radius: 5px;
      background: #fff;
      max-width: 100%;
    }
    .def-warning {
      color: red;
      font-size: 70%;
    }
    textarea {
      display: block;
      width: 100%;
      height: 80px;
      font-size: inherit;
      padding: 5px 10px;
      border: solid darken(@grey, 10) 1px;
      border-radius: 5px;
      background: #fff;
      resize: none;
      &.big {
        height: 300px;
      }
      &.resize {
        resize: vertical;
      }
    }
    code {
      background: #fff;
      text-transform: none;
      padding: 1px 6px;
      font-size: 18px;
      border-radius: 4px;
      color: @default-text;
      &.example {
        display: block;
        border-radius: 0px;
        cursor: pointer;
        margin-bottom: 8px;
        padding: 4px 8px;
      }
    }
    table {
      font-size: inherit;
      color: inherit;
    }
    .small-dismiss {
      line-height: 38px;
      padding-left: 10px;
    }
    .minor {
      font-size: 12px;
      color: #777;
      .minor-link {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .with-i {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 0.3rem;
      }
    }

    .test-time-overview {
      display: flex;

      .test-time-overview-content {
        margin-right: 10px;
      }
    }

    .module-picker-wrapper {
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
    }

    .test-option-checkbox {
      display: flex;
      align-content: center;

      .checkbox {
        flex-shrink: 0;
        margin-right: 10px;
      }

      .label {
        position: unset;
      }
    }

    .cover-preview {
      width: 400px;
      height: 150px;
      background-size: cover;
      border: 1px solid gray;
    }

    .upload-button {
      position: relative;
      height: 40px;
      line-height: 40px;
      padding: 0 20px 0 45px;
      background-position: 15px 9px;
      background-size: auto 50%;
      input[type='file'] {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 10;
      }
    }
    .attachment {
      position: relative;
      background-color: #eeeeee;
      margin: 10px;
      line-height: 1.3rem;
      .attachment-buttons {
        display: flex;
        float: right;
        gap: 0.5rem;
        position: relative;
        bottom: 10px;
      }
    }
    .footnote {
      font-size: 11px;
      color: #555;
      font-style: italic;
    }

    .joinCode {
      display: inline-block;
      background: #fff;
      border-radius: 10px;
      margin: 0 1% 3% 0;
      font-size: 60px;
      line-height: 1em;
      text-align: center;
      color: #574c4a;
      font-family: 'odin';
      padding: 10px 20px;
    }
    .joinCode-small {
      display: block;
      background: #fff;
      border-radius: 10px;
      margin-top: 15px;
      font-size: 30px;
      line-height: 1em;
      text-align: center;
      color: #574c4a;
      font-family: 'odin';
      padding: 5px 10px;
    }
  }
  .tagresults {
    margin: 20px 0;
    .tag {
      border-radius: 20px;
      display: inline-block;
      background-color: @cyan;
      color: white;
      padding: 5px 15px;
      margin-bottom: 5px;
    }
  }
  #spinner {
    top: 60px;
  }
}

.radiobutton-list {
  position: relative;
  min-height: 30px;
  margin-bottom: 10px;
  .radiobutton {
    position: relative;
    width: 100%;
    .radio {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      width: 25px;
      height: 25px;
      border: 4px solid #365054;
      border-radius: 100%;
    }
    &.active .radio:after {
      content: '';
      position: absolute;
      background-color: #365054;
      height: 11px;
      width: 11px;
      top: 3px;
      left: 3px;
      border-radius: 100%;
    }
  }
  .label {
    line-height: 2em;
    cursor: pointer;
    color: #365054;
    padding-left: 35px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.collapse-well {
  overflow-y: hidden;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  .transition(all 0.2s ease-out);
  padding: 10px;
  &.collapse {
    position: absolute;
    border: none;
    padding: 0 11px;
    height: 0px;
  }
  &.check-right {
    .checkbox {
      float: right;
    }
    .label {
      left: inherit;
      right: 50px;
    }
  }
}
