@import (once) url('../_imports.less');
#dragQuestion {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  .image {
    position: absolute;
    /* make the image full screen background by default */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .drag-components [data-drag-question-source='true'].animate-move {
    .transition(all 0.3s ease-out);
    cursor: pointer !important;
    .drag-question-object-grippy {
      cursor: pointer;
    }
  }
  .drag-components [data-drag-question-source='true'].active {
    z-index: 20;
  }
  &.view-mode {
    .text-value {
      pointer-events: none;
    }
  }
}

#gameScreen #dragQuestion #progress-answers {
  left: 0;
  top: 40%;
  padding-left: 0;
  .nr {
    left: 0;
    width: 22%;
    text-align: right;
  }
  .progress-bar {
    left: 23%;
  }
}
#realtime-player {
  .show-student-results {
    margin-top: 1em;
    display: inline-block;
  }
  &.unknown {
    .show-student-results {
      margin-top: 1em;
      background-color: @orange;
      color: white;
    }
  }
}
