@import (once) url('../_imports.less');

.component-image {
  &.is-full-screen {
    position: fixed;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: block !important;
    z-index: 98;
    .comp-image {
      border-radius: 0 !important;
    }
    .drag-question-object-outline,
    .drag-question-object-grippy {
      display: none;
    }
  }
  .comp-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    &.placeholder {
      background-color: rgba(0, 0, 0, 0.15);
      .button {
        top: 50%;
        left: 50%;
        .transform(translate(-50%, -50%));
        background-color: transparent;
      }
    }
  }
}

.comp-image.allowzoom-true {
  cursor: zoom-in;
}

&.zoom-1 .component-image.is-full-screen .comp-image {
  background-size: contain !important;
  cursor: zoom-in;
}
&.zoom-2 .component-image.is-full-screen .comp-image {
  background-size: cover !important;
  cursor: move;
}
&.zoom-3 .component-image.is-full-screen .comp-image {
  background-size: 300% auto !important;
}
&.zoom-4 .component-image.is-full-screen .comp-image {
  background-size: 400% auto !important;
}
&.zoom-5 .component-image.is-full-screen .comp-image {
  background-size: 500% auto !important;
}

&.zoom-1 .component-image.is-full-screen.comp-image {
  background-size: contain !important;
  cursor: zoom-in;
}
&.zoom-2 .component-image.is-full-screen.comp-image {
  background-size: cover !important;
  cursor: move;
}

.fullscreen-non-comp-image .full-screen-image {
  .non-comp-image {
    max-width: 100vw !important;
    max-height: 100vh !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 21;
    background-color: black;
    background-position-x: 50%;
  }

  .show-in-fullscreen {
    display: block !important;
  }

  .hide-in-fullscreen {
    display: none !important;
  }
}
