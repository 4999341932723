@glasspane: rgba(0, 0, 0, 0.6);
/*rgba(0, 150, 150, 0.8);*/
/* don't use blue yet, it's ugly here and there */

// August 2021 styleguide: https://company-47241.frontify.com/d/bOQTrj0W7lLt/styleguide#/basics/kleuren
@dark-grey: #5a4d4c;
@turquoise: #00b6bc;
@blue: #4dc6e6;
@yellow: #f8c82d;
@red-new: #ec484d;
@green-new: #4caf50;
@pacific-blue: #00a5c2;
@pacific-blue-faded: fadeout(@pacific-blue, 85%);

@white: #ffffff;
@silver: #c3c3c3;
@mercury: #e0e0e0;
@wild-sand: #f3f3f3;
@hurricane: #8d8786;
@outline: #dee0e4;

// Design system colors
@surface: #fcfcfc;
@surface-plus-2: #edeef0;
@disabled-background: rgba(0, 19, 67, 0.125);
@disabled-surface: rgba(0, 15, 44, 0.38);
@additional-disabled: rgba(0, 19, 67, 0.075);

/* brampalette */
@border-dark: #ccc;
@grey-bg: @mercury;
@border: @grey-bg;
@brown-dark: @dark-grey;
@default-text: @brown-dark;

// brandColors
@brand-stap-voor-stap-color: #fe5f55;
@brand-stap-voor-stap-color-dark: darken(@brand-stap-voor-stap-color, 18);

// Other
@white-2: @wild-sand;
@black: #000000;
@black-trans: fadeout(@black, 25%);
@red: #e72138;
@red-faded: fadeout(@red, 90%);
@red-dark: darken(@red, 15%);
@red-new-faded: fadeout(@red-new, 90%);
@red-new-dark: darken(@red-new, 15%);
@yellow-trans: fadeout(@yellow, 25%);
@yellow-dark: darken(@yellow, 15%);
@yellow-dark-border-color: #e2b628;
@yellow-faded: fadeout(@yellow, 85%);
@light-blue: #f3fcff;
@blue-dark: darken(@blue, 15%);
@blue-dark2: #0a5569;
@blue-darker: #00659d;
@blue-deep: #0076ff;
@blue-trans: fadeout(@blue, 25%);
@blue-light-faded: fadeout(@blue, 75%);
@dark-grey-faded: fadeout(@dark-grey, 85%);
@green: #0aba58;
@green-dark: darken(@green, 15%);
@green-mid: #008286;
@pink: @red-new;
@grey: #ececec;
@grey-dark: #574c4a;
@grey-dark-2: @grey-dark;
@grey-mid: #635655;
@grey-mid-2: #999999;
@grey-mid-3: @hurricane;
@grey-light: #e9e9e9;
@grey-light2: #aaa;
@grey-light3: #cecece;
@grey-faded: fadeout(@dark-grey, 85%);
@orange: #f48221;
@cyan: @turquoise;
@government: #154273;
@postit: #fffdd6;
@correct: #2a8f27;
@correct-light: lighten(@correct, 25);
@green-new-faded: fadeout(@green-new, 90%);
@link: #009bff;
@purple: #ca4cfd;
@folder-blue: #578dce;
@air-blue: #008aaa;
@none: 'transparent';
@scrollbar-foreground: @hurricane;
@scrollbar-boxshadow-color: @silver;

.color-default {
  color: @default-text !important;
}

.bg-red {
  background-color: @red !important;
}

.bg-red-dark {
  background-color: @red-dark !important;
}

.bg-yellow {
  background-color: @yellow !important;
}

.bg-yellow-dark {
  background-color: @yellow-dark !important;
}

.bg-blue {
  background-color: @blue !important;
}

.bg-blue-dark {
  background-color: @blue-dark !important;
}

.bg-blue-darker {
  background-color: @blue-darker !important;
}

.bg-green {
  background-color: @green !important;
}

.bg-green-dark {
  background-color: @green-dark !important;
}

.bg-pink {
  background-color: @pink !important;
}

.bg-grey {
  background-color: @grey !important;
}

.bg-grey-mid {
  background-color: @grey-mid !important;
}

.bg-grey-dark {
  background-color: @grey-dark !important;
}

.bg-silver {
  background-color: @silver !important;
}

.bg-brown-dark {
  background-color: @brown-dark !important;
}

.bg-orange {
  background-color: @orange !important;
}

.bg-white {
  background-color: @white !important;
}

.bg-white-2 {
  background-color: @white-2 !important;
}

.bg-white-tp {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-black {
  background-color: @black !important;
}

.bg-cyan {
  background-color: @cyan !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-correct {
  background-color: @correct !important;
}

.bg-purple {
  background-color: @purple !important;
}

.bg-correct-light {
  background-color: @correct-light !important;
}

.fg-red {
  color: @red !important;
}

.fg-red-dark {
  color: @red-dark !important;
}

.fg-yellow {
  color: @yellow !important;
}

.fg-yellow-dark {
  color: @yellow-dark !important;
}

.fg-blue {
  color: @blue !important;
}

.fg-blue-dark {
  color: @blue-dark !important;
}

.fg-green {
  color: @green !important;
}

.fg-green-dark {
  color: @green-dark !important;
}

.fg-pink {
  color: @pink !important;
}

.fg-grey {
  color: @grey !important;
}

.fg-grey-mid {
  color: @grey-mid !important;
}

.fg-grey-mid-2 {
  color: @grey-mid-2 !important;
}

.fg-grey-dark {
  color: @grey-dark !important;
}

.fg-silver {
  color: @silver !important;
}

.fg-orange {
  color: @orange !important;
}

.fg-white {
  color: @white !important;
}

.fg-white-2 {
  color: @white-2 !important;
}

.fg-black {
  color: @black !important;
}

.fg-cyan {
  color: @cyan !important;
}

.fg-purple {
  color: @purple !important;
}

.fg-text {
  color: @default-text !important;
}

.border-light {
  border: 2px solid @border;
}

.border-dark {
  border: 2px solid @border-dark;
}

.border-color {
  border: 2px solid @black;
}

.outline-light {
  outline: 2px solid @border;
}

.outline-dark {
  outline: 2px solid @border-dark;
}

.outline-color {
  outline: 2px solid @black;
}

.bc-red {
  border-color: @red !important;
}

.bc-red-dark {
  border-color: @red-dark !important;
}

.bc-yellow {
  border-color: @yellow !important;
}

.bc-yellow-dark {
  border-color: @yellow-dark !important;
}

.bc-blue {
  border-color: @blue !important;
}

.bc-blue-dark {
  border-color: @blue-dark !important;
}

.bc-green {
  border-color: @green !important;
}

.bc-green-dark {
  border-color: @green-dark !important;
}

.bc-pink {
  border-color: @pink !important;
}

.bc-grey {
  border-color: @grey !important;
}

.bc-grey-mid {
  border-color: @grey-mid !important;
}

.bc-grey-mid-2 {
  border-color: @grey-mid-2 !important;
}

.bc-grey-dark {
  border-color: @grey-dark !important;
}

.bc-orange {
  border-color: @orange !important;
}

.bc-white {
  border-color: @white !important;
}

.bc-white-2 {
  border-color: @white-2 !important;
}

.bc-black {
  border-color: @black !important;
}

.bc-cyan {
  border-color: @cyan !important;
}

.bc-purple {
  border-color: @purple !important;
}

.fill-red {
  fill: @red !important;
}

.fill-red-dark {
  fill: @red-dark !important;
}

.fill-yellow {
  fill: @yellow !important;
}

.fill-yellow-dark {
  fill: @yellow-dark !important;
}

.fill-blue {
  fill: @blue !important;
}

.fill-blue-dark {
  fill: @blue-dark !important;
}

.fill-green {
  fill: @green !important;
}

.fill-green-dark {
  fill: @green-dark !important;
}

.fill-pink {
  fill: @pink !important;
}

.fill-grey {
  fill: @grey !important;
}

.fill-grey-dark {
  fill: @grey-dark !important;
}

.fill-orange {
  fill: @orange !important;
}

.fill-white {
  fill: @white !important;
}

.fill-white-2 {
  fill: @white-2 !important;
}

.fill-black {
  fill: @black !important;
}

.fill-cyan {
  fill: @cyan !important;
}

.schooltype-color {
  background: @blue !important;
  color: #fff !important;
}

.schooltype-color-po {
  background-color: @green !important;
}

.schooltype-color-vo {
  background-color: @yellow !important;
  color: @default-text !important;
}
