@import (once) url('../../../../../rootImports.less');

.fact {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  text-transform: lowercase;
  padding: 4px 8px;
  font-size: 9px;
  line-height: 16px;
  text-transform: uppercase;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px 0 0 0;

  &.unsafe {
    background: @red;
    color: #fff;
  }
}
