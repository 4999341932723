@import (once) url('../../../../../../rootImports.less');

@label-margin: 0.125rem;

.input__error {
  color: @red-new;
  margin-top: @label-margin * 2;
  font-size: 0.8125rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    min-height: 1rem;
    max-height: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.3rem;
    font-weight: 400;
    line-height: 1rem;
  }

  svg {
    height: 0.6rem;
  }
}
