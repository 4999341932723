@import (once) url('../../../rootImports.less');

@media (max-width: 768px) {
  [data-show-exclusive='portrait'] {
    display: inherit;
  }
  [data-show-exclusive='landscape'] {
    display: none;
  }
}
@media (min-width: 769px) {
  [data-show-exclusive='portrait'] {
    display: none;
  }
  [data-show-exclusive='landscape'] {
    display: inherit;
  }
}

.all-icons(@assetsUrl);
.all-images(@assetsUrl);

html.touch {
  .no-touch-only {
    display: none;
  }
}
html.no-touch {
  .touch-only {
    display: none;
  }
}

* {
  .box-sizing (border-box);
  -ms-overflow-style: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html.win::-webkit-scrollbar,
html.win *::-webkit-scrollbar {
  width: 12px;
}
html.win::-webkit-scrollbar-track,
html.win *::-webkit-scrollbar-track {
  padding: 4px;
}
html.win::-webkit-scrollbar-thumb,
html.win *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
}
table {
  border: none;
  &.non-data {
    width: 100%;
    font-size: inherit;
    position: relative;
    td {
      vertical-align: top;
    }
  }
}
input:not([type='checkbox']),
textarea {
  -webkit-appearance: none;
}
.a {
  text-decoration: underline;
  cursor: pointer;
}
.abs {
  position: absolute;
}
.rel {
  position: relative;
}
.ov {
  overflow: auto;
}
.breaker {
  clear: both;
  display: table;
  zoom: 1;
}
.inactive {
  opacity: 0.3;
}
.explain {
  cursor: help;
}
#version {
  position: absolute;
  left: 10px;
  bottom: 10px;
  .opacity (1);
  z-index: 10;
  font-size: 12px;
  cursor: help;
  background: @green-dark;
  color: #fff;

  td {
    padding: 2px 5px;
  }

  td:first-child {
    font-weight: bold;
  }

  &.hidden {
    display: block;
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.05);
    .overflow-hidden;
    color: transparent;
    border-radius: 100px;
  }
}
html .green {
  background: #3da364;
}

html,
body {
  margin: 0;
  font-family: @lessonup-body-new;
  -ms-overflow-style: none !important;

  input,
  textarea,
  [contenteditable],
  [contentEditable],
  [contenteditable] *,
  [contentEditable] * {
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text;
  }

  [contenteditable],
  [contentEditable] {
    word-wrap: break-word;
  }
}
.bold-references b {
  color: @cyan;
}
a {
  color: inherit;
}
.soft-hidden {
  position: absolute;
  left: -99999px;
}
.inline {
  display: inline;
}
.ib {
  display: inline-block;
  float: none;
}
.hc {
  text-align: center !important;
  clear: both;

  .big-button {
    margin: 5px;
  }

  &.padded {
    padding-top: 0.4em;
  }
}
.hc.vc {
  left: 0;
}
.position-bottom {
  position: absolute;
  bottom: 30px;
}
.hf {
  text-align: justify;
}
.hr {
  text-align: right;
}
.hl {
  text-align: left;
}
.vc {
  width: 100%;
  position: absolute;
  top: 50%;
  .transform(translateY(-50%));
}
.centerx {
  position: absolute;
  transform: translate(50%, 50%);
  right: 50%;
}
.centerxy {
  left: 50%;
  top: 50%;
  .transform(translate(-50%, -50%));
}
.full-no-overflow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .overflow-hidden;

  .upload-button {
    width: 100%;
    height: 100%;
    .opacity (0);
  }
}
.notice-red {
  padding: 4px 8px;
  border-radius: 4px;
  background: @red;
  color: #fff;
}
.notice-yellow {
  padding: 4px 8px;
  border-radius: 4px;
  background: @yellow;
  color: @default-text;
}
#main {
  overflow: auto;
}
code {
  font-size: 20px;
  text-transform: uppercase;
}
.full {
  width: 100%;
}
.left {
  float: left;
}
.right {
  float: right;
}
.hidden {
  display: none;
}
.ta-right {
  text-align: right;
}
[draggable] {
  .user-select (none);
}
[draggable] * {
  .user-drag (none);
}

.selectable {
  .user-select(text);
}

.rocket {
  position: absolute;
  width: 300px;
  height: 300px;
  background: url('@{assetsUrl}/img/rocket1.png') no-repeat center center;
  background-size: 100% auto;

  &.half {
    width: 150px;
    height: 150px;
    &.centered {
      margin: -75px 0 0 -75px;
    }
  }

  &.centered {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -150px 0 0 -150px;
  }
  &.fly {
    .animation (fly infinite 5s ease-in-out);
  }
  &.boost {
    .animation (fly infinite 0.1s ease-in-out);
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.wrapper-link {
  text-decoration: none;
}

.inline-icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  background-position: center;
  background-size: 80% auto;
  background-repeat: no-repeat;
  &.small {
    width: 1em;
    height: 1em;
  }
}

.password-keyboard {
  width: 220px;
  margin: auto;
  .icon-button {
    cursor: pointer;
    width: 60px;
    height: 60px;
    margin: 5px;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    background-position: 65% 65%;
    background-size: 50% auto;
    background-repeat: no-repeat;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    .number {
      font-size: 1.2em;
      padding: 5px;
    }
    &.back {
      background-color: red;
    }
    &.empty {
      pointer-events: none;
      visibility: hidden;
    }
  }
}
